// Normally I would preffer this method to return an array with all the data so then i could just assign it to the object within the component, however at this point I still don't know how to
// export it in such way so I'm passing the form into this metchod and i will assigned this way :( 

import { TranslationService } from 'sizing-shared-lib'
import { EHPricingOptions } from '../models/pricingOptions.model';

export function setInitialEnumerationValuesForPricing(initialPricingOptions: EHPricingOptions[], api: TranslationService) {
  (async () => {

    //these two variables below need to be populated dynamically from one of the services in case if in the future we will add a new option or entire unit -- !!!! NEED TO BE FIXED !!!!
    let heatingUnits: string[] = ['EH_DHW_DC', 'EH_DHW_SC', 'EH_HTG_CC', 'EH_HTG_DC', 'EH_HTG_SC']
    let optionsNames: string[] = ['GasketMaterial', 'Actuator', 'CondensateRemoval', 'HighLimit', 'ManualIsolation', 'SystemRecirculation', 'ControlSystem', 'FrameType', 'PackageSupports', 'PanelLocation', 'Extras', 'IntegrityTest', 'DataCollection', 'Communications', 'ServiceOffering', 'ControlValveFeedback', 'FA11', 'PA31', 'TA11', 'TA31', 'TA41', 'TA01', 'UPS', 'Insulation']


    for await (var heatingUnit of heatingUnits) {
      for (var optionName of optionsNames) {

        var optionData = new EHPricingOptions();

        var items = api.displayGroup.enumerations.filter(mp => mp.opCoOverride === true && mp.enumerationName === heatingUnit + '_' + optionName)[0];
        if (!!items && !!items.enumerationDefinitions && items.enumerationDefinitions.length > 0) {
          optionData.EnumerationName = heatingUnit + '_' + optionName;
          optionData.SelectedValue = items.enumerationDefinitions[0].value;
          initialPricingOptions.push(optionData);
        }
        else {
          items = api.displayGroup.enumerations.filter(mp => mp.opCoOverride === false && mp.enumerationName === heatingUnit + '_' + optionName)[0];
          optionData.EnumerationName = heatingUnit + '_' + optionName;
          optionData.SelectedValue = items.enumerationDefinitions[0].value;
          initialPricingOptions.push(optionData);
        }
      }
    }
  })();
}
  
