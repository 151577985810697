import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IGenericChanges } from '../../../../sizing-shared-lib/src/lib/modules/generic.changes.interface';
import { EasiHeatUnitFormGroup } from '../models/form-groups/EasiHeatUnitFormGroup';
import { EasiHeatUnitsFormGroup } from '../models/form-groups/EasiHeatUnitsFormGroup';
import { PricingOptionsFormGroup } from '../models/form-groups/PricingOptionsFormGroup';
import { UnitOptionsFormGroup } from '../models/form-groups/UnitOptionsFormGroup';
import { FormGroupConfig } from '../models/form-groups/FormGroupConfiguration';
import { SpecificationSheetValuesCollection } from '../models/specificationSheetValuesCollection.model';
import { UnitType } from '../models/UnitType';
import { ModulePreferenceModel } from '../models/ModulePreferencesModel';
import { DisplayPreferenceDirective, TranslatePipe, UnitsService, EnumerationComponent, TranslationService, Preference } from 'sizing-shared-lib';
import { LocaleService, LocaleValidation } from 'node_modules/angular-l10n';
import { EasiheatGen4Output } from '../models/output.model';
import { convertToDefaultValueInPageUnits } from '../unit-converter/unitConverter';
import { Units } from '../models/unitsModel';
import { EHPricingOptions, EHPricingSummaryOutput, EHUnitPricingOutput } from '../models/pricingOptions.model';
import { EasiHeatGen4Component } from '../easiheat-gen4/easiheatgen4.component';
import { isNull, isNullOrUndefined, isUndefined } from 'util';
import { EnumerationNames } from '../models/selectedEnumerationNames.model';
import { ProcessConditionsFormGroup } from '../models/form-groups/ProcessConditionsFormGroup';
import { Manufacturer } from '../../../../sizing-shared-lib/src/lib/modules/admin/module-preferences/manufacturer.model';
import { EasiHeatGen4Service } from '../services/easiheatgen4.services';
import { DisplayGroup } from '../../../../sizing-shared-lib/src/lib/shared/translation/translation.model';
import { EnergySavingsOutputFormGroup } from '../models/form-groups/EnergySavingsOutputFormGroup';
import { EnergySavingOutput } from '../models/energySavingOutput.model';
import { EnergySavingsFuelInfoInterface } from '../models/energySavingFuelInfo.interface';
import { deCamelCase } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-easiheat-sizing-results',
  templateUrl: './easiheat-sizing-results.component.html',
  styleUrls: ['./easiheat-sizing-results.component.scss'],
  providers: []
})



export class EasiheatSizingResultsComponent implements OnInit {

  resultsForm: FormGroup;
  specificationSheetValuesCollection: SpecificationSheetValuesCollection;
  @Output() unitOptionChanged = new EventEmitter<{ options: EHPricingOptions[], side: string }>();
  @Output() pricingOptionChanged = new EventEmitter<{ form: PricingOptionsFormGroup, side: string }>();
  @Output() reSizeUnit = new EventEmitter<{ index: number, optionName: string, value: string }>();
  @Output() updateSpecSheetValues = new EventEmitter<{ side: string }>();
  @Output() updateJobStatusId = new EventEmitter<{ jobStatusId: number }>();
  //@Output() specificationSheetValuesChanged = new EventEmitter<SpecificationSheetValuesCollection>();
  @Input() processConditions: ProcessConditionsFormGroup;
  @Input() temperatureUnit: DisplayPreferenceDirective;
  @Input() pressureUnit: DisplayPreferenceDirective;
  @Input() unitType; // used in html page to drive visibility of units option enumerations  
  @Input() modPref: ModulePreferenceModel;
  @Input() hideAllPricing: boolean;
  @Input() hideManufactureCosts: boolean;
  @Input() globalVariance: string;
  isLoadingJob: boolean = false;
  //@Input("sizingResult") set sizingResponse(response: EasiheatGen4Output) {
  //  this.displaySizingResults(response)
  //}
  currencySymbol: string[] = [];
  hasCurrencySymbol = false;
  emissionUnitTranslated: string = '';
  energyUnitTranslated: string = '';
  massFlowUnitTranslated: string = '';
  volumeUnitTranslated: string = '';
  temperatureUnitTranslated: string = '';
  //internal_ServiceOfferingPrice: number[] = [];
  serviceOfferingKey: string[] = [];
  display_ServiceOfferingPrice: number[] = [];
  public selectedEnumerationNames: EnumerationNames = new EnumerationNames();
  public actualCondensateRemovalValue: string[] = [];
  public initilaPricing: boolean = true;

  firstSelectedUnitCalcs: string;
  secondSelectedUnitCalcs: string;
  unitTypeHeaderName: string[] = ["", ""];
  isCC: boolean[] = [];
  htgCcInletPipeVeloctiyOver40ms: boolean = false;
  convertedToBargPressure: number;

  //Energy Saving Interpolation Values and Units
  // Units
  steamMassSaved_Unit: string;
  co2eMassSaving_Unit: string;
  flashEnergySaving_Unit: string;
  // Fuel Info Values
  fuelName: string;
  fuelKcalValue: string;
  fuelKcalUnit: string;
  fuelCO2eValue: string;
  fuelCO2eUnit: string;
  // Values left
  leftUnitType_Value: string;

  leftSteamConsumptionReductionPercent_Value: number;
  leftSteamMassSaved_Value: number;

  leftCo2eEmissionReductionPerYear_Value: number;
  leftCo2eEmissionCostReductionPerYear_Value: number;

  leftEnergySavedPerYear_Value: number;
  leftEnergyCostReductionPerYear_Value: number;

  leftWaterSavedPerYear_Value: number;
  leftWaterCostReductionPerYear_Value: number;

  leftTotalEvcCostReductionPerYear_Value: number; 
  //Values right
  rightUnitType_Value: string;
  rightSteamConsumptionReductionPercent_Value: number;
  rightSteamMassSaved_Value: number;
  rightCo2eEmissionReductionPerYear_Value: number;
  rightCo2eEmissionCostReductionPerYear_Value: number;
  rightEnergySavedPerYear_Value: number;
  rightEnergyCostReductionPerYear_Value: number;
  rightWaterSavedPerYear_Value: number;
  rightWaterCostReductionPerYear_Value: number;
  rightTotalEvcCostReductionPerYear_Value: number; 


  //Enumerations Filter Lists
  gasketMaterial_FilterLists: string[][] = [];
  actuator_FilterLists: string[][] = [];
  condensateRemoval_FilterLists: string[][] = [];
  highLimit_FilterLists: string[][] = [];
  manualIsolation_FilterLists: string[][] = [];
  systemRecirculation_FilterLists: string[][] = [];
  controlSystem_FilterLists: string[][] = [];
  frameType_FilterLists: string[][] = [];
  packageSupports_FilterLists: string[][] = [];
  panelLocation_FilterLists: string[][] = [];
  extras_FilterLists: string[][] = [];
  integrityTest_FilterLists: string[][] = [];
  dataCollection_FilterLists: string[][] = [];
  controlValveFeedback_FilterLists: string[][] = [];
  ta11_FilterLists: string[][] = [];
  ta31_FilterLists: string[][] = [];
  ta41_FilterLists: string[][] = [];
  ta01_FilterLists: string[][] = [];
  pa31_FilterLists: string[][] = [];
  fa11_FilterLists: string[][] = [];
  ups_FilterLists: string[][] = [];
  communications_FilterLists: string[][] = [];
  insulation_FilterLists: string[][] = [];
  actualSecondaryPressureDropUnit: string;

  controlSystem_TouchLists: boolean[][] = [[false], [false]];
  dataCollection_TouchLists: boolean[][] = [[false], [false]];
  ta11_TouchLists: boolean[][] = [[false], [false]];
  ta41_TouchLists: boolean[][] = [[false], [false]];
  ta01_TouchLists: boolean[][] = [[false], [false]];
  fa11_TouchLists: boolean[][] = [[false], [false]];
  ta31_TouchLists: boolean[][] = [[false], [false]];
  pa31_TouchLists: boolean[][] = [[false], [false]];
  extras_TouchLists: boolean[][] = [[false], [false]];
  controlValveFeedback_TouchLists: boolean[][] = [[false], [false]];
  integrityTest_TouchLists: boolean[][] = [[false], [false]];
  condensateRemoval_TouchLists: boolean[][] = [[false], [false]];
  gasketMaterial_TouchLists: boolean[][] = [[false], [false]];
  manualIsolation_TouchLists: boolean[][] = [[false], [false]];
  ups_TouchLists: boolean[][] = [[false], [false]];
  highLimit_TouchLists: boolean[][] = [[false], [false]];
  actuator_TouchLists: boolean[][] = [[false], [false]];
  communications_TouchLists: boolean[][] = [[false], [false]];

  performEnergySavingCalc: boolean = false;

  leftNomenclature: boolean = false;
  rightNomenclature: boolean = false;


  /* below properties energySavingSubPanel{side} will determin which SCSS class (disabled or enabled )
  schould be applied to the div that containes Enargy Saving figures depending if product sizing process returned any results for the relevant Panel side.
  HTML component easiheat-sizing-results.component2 has coresponding interpolation values for relevent div - class="{{energySavingSubPanelLeft}}" or class="{{energySavingSubPanelRight}}*/
  energySavingSubPanelLeft: string = 'energySavingSubPanelEnabled'; //default value
  energySavingSubPanelRight: string = 'energySavingSubPanelEnabled'; //default value

  @ViewChild("leftGasketMaterial", { static: true }) leftGasketMaterial: EnumerationComponent;
  @ViewChild("leftActuator", { static: true }) leftActuator: EnumerationComponent;
  @ViewChild("leftCondensateRemoval", { static: true }) leftCondensateRemoval: EnumerationComponent;
  @ViewChild("leftHighLimit", { static: true }) leftHighLimit: EnumerationComponent;
  @ViewChild("leftManualIsolation", { static: true }) leftManualIsolation: EnumerationComponent;
  @ViewChild("leftSystemRecirculation", { static: true }) leftSystemRecirculation: EnumerationComponent;
  @ViewChild("leftControlSystem", { static: true }) leftControlSystem: EnumerationComponent;
  @ViewChild("leftFrameType", { static: true }) leftFrameType: EnumerationComponent;
  @ViewChild("leftPackageSupports", { static: true }) leftPackageSupports: EnumerationComponent;
  @ViewChild("leftPanelLocation", { static: true }) leftPanelLocation: EnumerationComponent;
  @ViewChild("leftExtras", { static: true }) leftExtras: EnumerationComponent;
  @ViewChild("leftIntegrityTest", { static: true }) leftIntegrityTest: EnumerationComponent;
  @ViewChild("leftDataCollection", { static: true }) leftDataCollection: EnumerationComponent;
  @ViewChild("leftControlValveFeedback", { static: true }) leftControlValveFeedback: EnumerationComponent;
  @ViewChild("leftTa11", { static: true }) leftTa11: EnumerationComponent;
  @ViewChild("leftTa31", { static: true }) leftTa31: EnumerationComponent;
  @ViewChild("leftTa41", { static: true }) leftTa41: EnumerationComponent;
  @ViewChild("leftTa01", { static: true }) leftTa01: EnumerationComponent;
  @ViewChild("leftPa31", { static: true }) leftPa31: EnumerationComponent;
  @ViewChild("leftFa11", { static: true }) leftFa11: EnumerationComponent;
  @ViewChild("leftUps", { static: true }) leftUps: EnumerationComponent;
  @ViewChild("leftCommunications", { static: true }) leftCommunications: EnumerationComponent;
  @ViewChild("leftInsulation", { static: true }) leftInsulation: EnumerationComponent;
  @ViewChild("leftServiceOffering", { static: true }) leftServiceOffering: EnumerationComponent;

  @ViewChild("rightGasketMaterial", { static: true }) rightGasketMaterial: EnumerationComponent;
  @ViewChild("rightActuator", { static: true }) rightActuator: EnumerationComponent;
  @ViewChild("rightCondensateRemoval", { static: true }) rightCondensateRemoval: EnumerationComponent;
  @ViewChild("rightHighLimit", { static: true }) rightHighLimit: EnumerationComponent;
  @ViewChild("rightManualIsolation", { static: true }) rightManualIsolation: EnumerationComponent;
  @ViewChild("rightSystemRecirculation", { static: true }) rightSystemRecirculation: EnumerationComponent;
  @ViewChild("rightControlSystem", { static: true }) rightControlSystem: EnumerationComponent;
  @ViewChild("rightFrameType", { static: true }) rightFrameType: EnumerationComponent;
  @ViewChild("rightPackageSupports", { static: true }) rightPackageSupports: EnumerationComponent;
  @ViewChild("rightPanelLocation", { static: true }) rightPanelLocation: EnumerationComponent;
  @ViewChild("rightExtras", { static: true }) rightExtras: EnumerationComponent;
  @ViewChild("rightIntegrityTest", { static: true }) rightIntegrityTest: EnumerationComponent;
  @ViewChild("rightDataCollection", { static: true }) rightDataCollection: EnumerationComponent;
  @ViewChild("rightControlValveFeedback", { static: true }) rightControlValveFeedback: EnumerationComponent;
  @ViewChild("rightTa11", { static: true }) rightTa11: EnumerationComponent;
  @ViewChild("rightTa31", { static: true }) rightTa31: EnumerationComponent;
  @ViewChild("rightTa41", { static: true }) rightTa41: EnumerationComponent;
  @ViewChild("rightTa01", { static: true }) rightTa01: EnumerationComponent;
  @ViewChild("rightPa31", { static: true }) rightPa31: EnumerationComponent;
  @ViewChild("rightFa11", { static: true }) rightFa11: EnumerationComponent;
  @ViewChild("rightUps", { static: true }) rightUps: EnumerationComponent;
  @ViewChild("rightCommunications", { static: true }) rightCommunications: EnumerationComponent;
  @ViewChild("rightInsulation", { static: true }) rightInsulation: EnumerationComponent;
  @ViewChild("rightServiceOffering", { static: true }) rightServiceOffering: EnumerationComponent;



  constructor(private fb: FormBuilder,
    private sizingApi: EasiHeatGen4Service,
    private translatePipe: TranslatePipe,
    private localeService: LocaleService,
    private unitsService: UnitsService,
    private translationService: TranslationService) {
  }

  getUnitOptionsValues(side: string): UnitOptionsFormGroup {
    return this.resultsForm.get(`${side}.unitOptions`).value;
  }

  createFormGroup() {

    const leftPriceOptionsConfig: FormGroupConfig<PricingOptionsFormGroup> = {
      markup: [null, { validators: [Validators.nullValidator], updateOn: 'blur' }],
      grossMargin: [null, { validators: [Validators.max(100), Validators.min(0)], updateOn: 'blur' }],
      serviceOffering: [{ value: null, disabled: false }],
      ssp: [{ value: null, disabled: false }],
      sellingPrice: [null, { validators: [Validators.nullValidator], updateOn: 'blur' }],
      serviceOfferingPrice: [{ value: null, disabled: false }],
      serviceOfferingFieldLabel: [{ value: null, disabled: false }],
      delivery: [{ value: null, disabled: false }],
      totalPrice: [{ value: null, disabled: false }],
      unit_and_eh_internal_SSP: [0],
      unit_and_eh_internal_SellingPrice: [0],
      unitSelected: [false],
      sellingMarkupUpdated: [false],
      grossMarginUpdated: [false],
      sellingPriceUpdated: [false]
    }

    const rightPriceOptionsConfig: FormGroupConfig<PricingOptionsFormGroup> = {
      markup: [null, { validators: [Validators.nullValidator], updateOn: 'blur' }],
      grossMargin: [null, { validators: [Validators.max(100), Validators.min(0)], updateOn: 'blur' }],
      serviceOffering: [{ value: null, disabled: false }],
      ssp: [{ value: null, disabled: false }],
      sellingPrice: [null, { validators: [Validators.nullValidator], updateOn: 'blur' }],
      serviceOfferingPrice: [{ value: null, disabled: false }],
      serviceOfferingFieldLabel: [{ value: null, disabled: false }],
      delivery: [{ value: null, disabled: false }],
      totalPrice: [{ value: null, disabled: false }],
      unit_and_eh_internal_SSP: [0],
      unit_and_eh_internal_SellingPrice: [0],
      unitSelected: [false],
      sellingMarkupUpdated: [false],
      grossMarginUpdated: [false],
      sellingPriceUpdated: [false]
    }

    const leftEnergySavingsConfig: FormGroupConfig<EnergySavingsOutputFormGroup> = {
      unitType: [{ value: null, disabled: false }],
      steamConsumptionReductionPercent: [{ value: null, disabled: false }],
      steamMassSaved: [{ value: null, disabled: false }],
      co2eEmmissionReductionPerYear: [{ value: null, disabled: false }],
      co2eEmmissionCostReductionPerYear: [{ value: null, disabled: false }],
      energySavedPerYear: [{ value: null, disabled: false }],
      energyCostReductionPerYear: [{ value: null, disabled: false }],
      waterSavedPerYear: [{ value: null, disabled: false }],
      waterCostReductionPerYear: [{ value: null, disabled: false }],
      totalEvcCostReductionPerYear: [{ value: null, disabled: false }],

    }

    const rightEnergySavingsConfig: FormGroupConfig<EnergySavingsOutputFormGroup> = {
      unitType: [{ value: null, disabled: false }],
      steamConsumptionReductionPercent: [{ value: null, disabled: false }],
      steamMassSaved: [{ value: null, disabled: false }],
      co2eEmmissionReductionPerYear: [{ value: null, disabled: false }],
      co2eEmmissionCostReductionPerYear: [{ value: null, disabled: false }],
      energySavedPerYear: [{ value: null, disabled: false }],
      energyCostReductionPerYear: [{ value: null, disabled: false }],
      waterSavedPerYear: [{ value: null, disabled: false }],
      waterCostReductionPerYear: [{ value: null, disabled: false }],
      totalEvcCostReductionPerYear: [{ value: null, disabled: false }],
    }

    const leftUnitOptionsConfig: FormGroupConfig<UnitOptionsFormGroup> = {
      nomenclature: [''],
      gasketMaterial: [{ value: null, disabled: false }],
      actuator: [{ value: null, disabled: false }],
      condensateRemoval: [{ value: null, disabled: false }],
      highLimit: [{ value: null, disabled: false }],
      manualIsolation: [{ value: null, disabled: false }],
      systemRecirculation: [{ value: null, disabled: false }],
      controlSystem: [{ value: null, disabled: false }],
      frameType: [{ value: null, disabled: false }],
      packageSupports: [{ value: null, disabled: false }],
      panelLocation: [{ value: null, disabled: false }],
      extras: [{ value: null, disabled: false }],
      integrityTest: [{ value: null, disabled: false }],
      dataCollection: [{ value: null, disabled: false }],
      controlValveFeedback: [{ value: null, disabled: false }],
      ta11: [{ value: null, disabled: false }],
      ta31: [{ value: null, disabled: false }],
      ta41: [{ value: null, disabled: false }],
      ta01: [{ value: null, disabled: false }],
      pa31: [{ value: null, disabled: false }],
      fa11: [{ value: null, disabled: false }],
      ups: [{ value: null, disabled: false }],
      communications: [{ value: null, disabled: false }],
      insulation: [{ value: null, disabled: false }],
    }

    const rightUnitOptionsConfig: FormGroupConfig<UnitOptionsFormGroup> = {
      nomenclature: [''],
      gasketMaterial: [{ value: null, disabled: false }],
      actuator: [{ value: null, disabled: false }],
      condensateRemoval: [{ value: null, disabled: false }],
      highLimit: [{ value: null, disabled: false }],
      manualIsolation: [{ value: null, disabled: false }],
      systemRecirculation: [{ value: null, disabled: false }],
      controlSystem: [{ value: null, disabled: false }],
      frameType: [{ value: null, disabled: false }],
      packageSupports: [{ value: null, disabled: false }],
      panelLocation: [{ value: null, disabled: false }],
      extras: [{ value: null, disabled: false }],
      integrityTest: [{ value: null, disabled: false }],
      dataCollection: [{ value: null, disabled: false }],
      controlValveFeedback: [{ value: null, disabled: false }],
      ta11: [{ value: null, disabled: false }],
      ta31: [{ value: null, disabled: false }],
      ta41: [{ value: null, disabled: false }],
      ta01: [{ value: null, disabled: false }],
      pa31: [{ value: null, disabled: false }],
      fa11: [{ value: null, disabled: false }],
      ups: [{ value: null, disabled: false }],
      communications: [{ value: null, disabled: false }],
      insulation: [{ value: null, disabled: false }],
    }

    const leftEasiHeatUnitConfig: FormGroupConfig<EasiHeatUnitFormGroup> = {

      easiHeatUnit: [null],
      trimType: [null],
      lenght: [null],
      height: [null],
      width: [null],
      weight: [null],
      packageInletConnectionSize: [null],
      controlValveSizeDisplay: [null],
      condensateValveSizeDisplay: [null],
      condensatePipeSize: [null],
      secondaryInletOutletPipeSize: [null],
      heatExchangePlateType: [null],
      heFrameType: [null],
      actualSecondaryPressureDrop: [null],
      numberOfPlates: [null],
      maximumCondensateTemperature: [null],
      epdmwGasketPressureCheck: [null],

      pricingOptions: this.fb.group(leftPriceOptionsConfig),
      energySavings: this.fb.group(leftEnergySavingsConfig),
      unitOptions: this.fb.group(leftUnitOptionsConfig),

      // Sizing Response Values Holders not displayed on the UI
      //--------------------------------------------------------
      controlValveSizeDn: [null],
      controlValveSizeAnsi: [null],
      steamCvKv: [null],
      steamCvCalculatedNoiseLevel: [null],
      condensateValveSizeDn: [null],
      condensateValveSizeAnsi: [null],
      condensateValveTrim: [null],
      condensateValveKv: [null],
      channelTypeSide1: [null],
      channelTypeSide2: [null],
      subCooling: [null],
      steamCvSplitRangeKv: [null]
      //--------------------------------------------------------
    }

    const rightEasiHeatUnitConfig: FormGroupConfig<EasiHeatUnitFormGroup> = {

      easiHeatUnit: [null],
      trimType: [null],
      lenght: [null],
      height: [null],
      width: [null],
      weight: [null],
      packageInletConnectionSize: [null],
      controlValveSizeDisplay: [null],
      condensateValveSizeDisplay: [null],
      condensatePipeSize: [null],
      secondaryInletOutletPipeSize: [null],
      heatExchangePlateType: [null],
      heFrameType: [null],
      actualSecondaryPressureDrop: [null],
      numberOfPlates: [null],
      maximumCondensateTemperature: [null],
      epdmwGasketPressureCheck: [null],

      pricingOptions: this.fb.group(rightPriceOptionsConfig),
      energySavings: this.fb.group(rightEnergySavingsConfig),
      unitOptions: this.fb.group(rightUnitOptionsConfig),

      // Sizing Response Values Holders not displayed on the UI
      //--------------------------------------------------------
      controlValveSizeDn: [null],
      controlValveSizeAnsi: [null],
      steamCvKv: [null],
      steamCvCalculatedNoiseLevel: [null],
      condensateValveSizeDn: [null],
      condensateValveSizeAnsi: [null],
      condensateValveTrim: [null],
      condensateValveKv: [null],
      channelTypeSide1: [null],
      channelTypeSide2: [null],
      subCooling: [null],
      steamCvSplitRangeKv: [null]
      //-------------------------------------------------------
    }

    const config: FormGroupConfig<EasiHeatUnitsFormGroup> = {

      left: this.fb.group(leftEasiHeatUnitConfig),
      right: this.fb.group(rightEasiHeatUnitConfig)
    }

    this.resultsForm = this.fb.group(config);

    this.resultsForm.get(`left.pricingOptions.markup`).valueChanges.subscribe((val) => this.onSellingMarkupChange(val, 'left'));
    this.resultsForm.get(`left.pricingOptions.grossMargin`).valueChanges.subscribe((val) => this.onGrossMarginChange(val, 'left'));
    this.resultsForm.get(`left.pricingOptions.sellingPrice`).valueChanges.subscribe(() => this.onSellingPriceChange('left'));
    this.resultsForm.get(`left.pricingOptions.unitSelected`).valueChanges.subscribe((val) => this.onUnitSelected(val, 'left', 'right'));
    this.resultsForm.get(`left.pricingOptions`).valueChanges.subscribe(() => this.onPricingOptionChange('left'));

    this.resultsForm.get(`left.unitOptions.gasketMaterial`).valueChanges.subscribe((val) => this.onUnitOptionChange('gasketMaterial', val, 'left'));
    this.resultsForm.get(`left.unitOptions.actuator`).valueChanges.subscribe((val) => this.onUnitOptionChange('actuator', val, 'left'));
    this.resultsForm.get(`left.unitOptions.condensateRemoval`).valueChanges.subscribe((val) => this.onUnitOptionChange('condensateRemoval', val, 'left'));
    this.resultsForm.get(`left.unitOptions.highLimit`).valueChanges.subscribe((val) => this.onUnitOptionChange('highLimit', val, 'left'));
    this.resultsForm.get(`left.unitOptions.manualIsolation`).valueChanges.subscribe((val) => this.onUnitOptionChange('manualIsolation', val, 'left'));
    this.resultsForm.get(`left.unitOptions.systemRecirculation`).valueChanges.subscribe((val) => this.onUnitOptionChange('systemRecirculation', val, 'left'));
    this.resultsForm.get(`left.unitOptions.controlSystem`).valueChanges.subscribe((val) => this.onUnitOptionChange('controlSystem', val, 'left'));
    this.resultsForm.get(`left.unitOptions.frameType`).valueChanges.subscribe((val) => this.onUnitOptionChange('frameType', val, 'left'));
    this.resultsForm.get(`left.unitOptions.packageSupports`).valueChanges.subscribe((val) => this.onUnitOptionChange('packageSupports', val, 'left'));
    this.resultsForm.get(`left.unitOptions.panelLocation`).valueChanges.subscribe((val) => this.onUnitOptionChange('panelLocation', val, 'left'));
    this.resultsForm.get(`left.unitOptions.extras`).valueChanges.subscribe((val) => this.onUnitOptionChange('extras', val, 'left'));
    this.resultsForm.get(`left.unitOptions.integrityTest`).valueChanges.subscribe((val) => this.onUnitOptionChange('integrityTest', val, 'left'));
    this.resultsForm.get(`left.unitOptions.dataCollection`).valueChanges.subscribe((val) => this.onUnitOptionChange('dataCollection', val, 'left'));
    this.resultsForm.get(`left.unitOptions.controlValveFeedback`).valueChanges.subscribe((val) => this.onUnitOptionChange('controlValveFeedback', val, 'left'));
    this.resultsForm.get(`left.unitOptions.ta11`).valueChanges.subscribe((val) => this.onUnitOptionChange('ta11', val, 'left'));
    this.resultsForm.get(`left.unitOptions.ta31`).valueChanges.subscribe((val) => this.onUnitOptionChange('ta31', val, 'left'));
    this.resultsForm.get(`left.unitOptions.ta41`).valueChanges.subscribe((val) => this.onUnitOptionChange('ta41', val, 'left'));
    this.resultsForm.get(`left.unitOptions.ta01`).valueChanges.subscribe((val) => this.onUnitOptionChange('ta01', val, 'left'));
    this.resultsForm.get(`left.unitOptions.pa31`).valueChanges.subscribe((val) => this.onUnitOptionChange('pa31', val, 'left'));
    this.resultsForm.get(`left.unitOptions.fa11`).valueChanges.subscribe((val) => this.onUnitOptionChange('fa11', val, 'left'));
    this.resultsForm.get(`left.unitOptions.ups`).valueChanges.subscribe((val) => this.onUnitOptionChange('ups', val, 'left'));
    this.resultsForm.get(`left.unitOptions.communications`).valueChanges.subscribe((val) => this.onUnitOptionChange('communications', val, 'left'));
    this.resultsForm.get(`left.unitOptions.insulation`).valueChanges.subscribe((val) => this.onUnitOptionChange('insulation', val, 'left'));

    this.resultsForm.get(`left`).valueChanges.subscribe(() => this.onUnitSelected(true, 'left', 'right'));


    this.resultsForm.get(`right.pricingOptions.markup`).valueChanges.subscribe((val) => this.onSellingMarkupChange(val, 'right'));
    this.resultsForm.get(`right.pricingOptions.grossMargin`).valueChanges.subscribe((val) => this.onGrossMarginChange(val, 'right'));
    this.resultsForm.get(`right.pricingOptions.sellingPrice`).valueChanges.subscribe(() => this.onSellingPriceChange('right'));
    this.resultsForm.get(`right.pricingOptions.unitSelected`).valueChanges.subscribe((val) => this.onUnitSelected(val, 'right', 'left'));
    this.resultsForm.get(`right.pricingOptions`).valueChanges.subscribe(() => this.onPricingOptionChange('right'));

    this.resultsForm.get(`right.unitOptions.gasketMaterial`).valueChanges.subscribe((val) => this.onUnitOptionChange('gasketMaterial', val, 'right'));
    this.resultsForm.get(`right.unitOptions.actuator`).valueChanges.subscribe((val) => this.onUnitOptionChange('actuator', val, 'right'));
    this.resultsForm.get(`right.unitOptions.condensateRemoval`).valueChanges.subscribe((val) => this.onUnitOptionChange('condensateRemoval', val, 'right'));
    this.resultsForm.get(`right.unitOptions.highLimit`).valueChanges.subscribe((val) => this.onUnitOptionChange('highLimit', val, 'right'));
    this.resultsForm.get(`right.unitOptions.manualIsolation`).valueChanges.subscribe((val) => this.onUnitOptionChange('manualIsolation', val, 'right'));
    this.resultsForm.get(`right.unitOptions.systemRecirculation`).valueChanges.subscribe((val) => this.onUnitOptionChange('systemRecirculation', val, 'right'));
    this.resultsForm.get(`right.unitOptions.controlSystem`).valueChanges.subscribe((val) => this.onUnitOptionChange('controlSystem', val, 'right'));
    this.resultsForm.get(`right.unitOptions.frameType`).valueChanges.subscribe((val) => this.onUnitOptionChange('frameType', val, 'right'));
    this.resultsForm.get(`right.unitOptions.packageSupports`).valueChanges.subscribe((val) => this.onUnitOptionChange('packageSupports', val, 'right'));
    this.resultsForm.get(`right.unitOptions.panelLocation`).valueChanges.subscribe((val) => this.onUnitOptionChange('panelLocation', val, 'right'));
    this.resultsForm.get(`right.unitOptions.extras`).valueChanges.subscribe((val) => this.onUnitOptionChange('extras', val, 'right'));
    this.resultsForm.get(`right.unitOptions.integrityTest`).valueChanges.subscribe((val) => this.onUnitOptionChange('integrityTest', val, 'right'));
    this.resultsForm.get(`right.unitOptions.dataCollection`).valueChanges.subscribe((val) => this.onUnitOptionChange('dataCollection', val, 'right'));
    this.resultsForm.get(`right.unitOptions.controlValveFeedback`).valueChanges.subscribe((val) => this.onUnitOptionChange('controlValveFeedback', val, 'right'));
    this.resultsForm.get(`right.unitOptions.ta11`).valueChanges.subscribe((val) => this.onUnitOptionChange('ta11', val, 'right'));
    this.resultsForm.get(`right.unitOptions.ta31`).valueChanges.subscribe((val) => this.onUnitOptionChange('ta31', val, 'right'));
    this.resultsForm.get(`right.unitOptions.ta41`).valueChanges.subscribe((val) => this.onUnitOptionChange('ta41', val, 'right'));
    this.resultsForm.get(`right.unitOptions.ta01`).valueChanges.subscribe((val) => this.onUnitOptionChange('ta01', val, 'right'));
    this.resultsForm.get(`right.unitOptions.pa31`).valueChanges.subscribe((val) => this.onUnitOptionChange('pa31', val, 'right'));
    this.resultsForm.get(`right.unitOptions.fa11`).valueChanges.subscribe((val) => this.onUnitOptionChange('fa11', val, 'right'));
    this.resultsForm.get(`right.unitOptions.ups`).valueChanges.subscribe((val) => this.onUnitOptionChange('ups', val, 'right'));
    this.resultsForm.get(`right.unitOptions.communications`).valueChanges.subscribe((val) => this.onUnitOptionChange('communications', val, 'right'));
    this.resultsForm.get(`right.unitOptions.insulation`).valueChanges.subscribe((val) => this.onUnitOptionChange('insulation', val, 'right'));

    this.resultsForm.get(`right`).valueChanges.subscribe(() => this.onUnitSelected(true, 'right', 'left'));


    return this.resultsForm;
  }

  ngOnInit(): void {
    this.isCC = [false, false];
  }

  private hashTable = [
    {
      //DHW Dual Control Options

      name: 'EH_DHW_DC',
      gasket_Material: 'EH_DHW_DC_GasketMaterial',
      actuation: 'EH_DHW_DC_Actuator',
      condensate_Removal: 'EH_DHW_DC_CondensateRemoval',
      high_Limit: 'EH_DHW_DC_HighLimit',
      man_Isolation: 'EH_DHW_DC_ManualIsolation',
      system_Recirculation: 'EH_DHW_DC_SystemRecirculation',
      control_System: 'EH_DHW_DC_ControlSystem',
      frame_Type: 'EH_DHW_DC_FrameType',
      package_Supports: 'EH_DHW_DC_PackageSupports',
      panel_Location: 'EH_DHW_DC_PanelLocation',
      extras: 'EH_DHW_DC_Extras',
      integrity_Test: 'EH_DHW_DC_IntegrityTest',
      data_Collection: 'EH_DHW_DC_DataCollection',
      communications: 'EH_DHW_DC_Communications',
      service_Offering: 'EH_DHW_DC_ServiceOffering',
      pressure_Drop: 'EH_DHW_DC_PressureDrop',
      controlValveFeedback: 'EH_DHW_DC_ControlValveFeedback',
      FA11: 'EH_DHW_DC_FA11',
      PA31: 'EH_DHW_DC_PA31',
      TA11: 'EH_DHW_DC_TA11',
      TA31: 'EH_DHW_DC_TA31',
      TA41: 'EH_DHW_DC_TA41',
      TA01: 'EH_DHW_DC_TA01',
      UPS: 'EH_DHW_DC_UPS',
      insulation: 'EH_DHW_DC_Insulation',

    },
    {
      //EH DHW Steam Control Options
      name: 'EH_DHW_SC',
      gasket_Material: 'EH_DHW_SC_GasketMaterial',
      actuation: 'EH_DHW_SC_Actuator',
      condensate_Removal: 'EH_DHW_SC_CondensateRemoval',
      high_Limit: 'EH_DHW_SC_HighLimit',
      man_Isolation: 'EH_DHW_SC_ManualIsolation',
      system_Recirculation: 'EH_DHW_SC_SystemRecirculation',
      control_System: 'EH_DHW_SC_ControlSystem',
      frame_Type: 'EH_DHW_SC_FrameType',
      package_Supports: 'EH_DHW_SC_PackageSupports',
      panel_Location: 'EH_DHW_SC_PanelLocation',
      extras: 'EH_DHW_SC_Extras',
      integrity_Test: 'EH_DHW_SC_IntegrityTest',
      data_Collection: 'EH_DHW_SC_DataCollection',
      communications: 'EH_DHW_SC_Communications',
      service_Offering: 'EH_DHW_SC_ServiceOffering',
      pressure_Drop: 'EH_DHW_SC_PressureDrop',
      controlValveFeedback: 'EH_DHW_SC_ControlValveFeedback',
      FA11: 'EH_DHW_SC_FA11',
      PA31: 'EH_DHW_SC_PA31',
      TA11: 'EH_DHW_SC_TA11',
      TA31: 'EH_DHW_SC_TA31',
      TA41: 'EH_DHW_SC_TA41',
      TA01: 'EH_DHW_SC_TA01',
      UPS: 'EH_DHW_SC_UPS',
      insulation: 'EH_DHW_SC_Insulation',
    },
    {
      //EH HTG Condensate Control Options
      name: 'EH_HTG_CC',//'EH_HTG_CC',
      gasket_Material: 'EH_HTG_CC_GasketMaterial',
      actuation: 'EH_HTG_CC_Actuator',
      condensate_Removal: 'EH_HTG_CC_CondensateRemoval',
      high_Limit: 'EH_HTG_CC_HighLimit',
      man_Isolation: 'EH_HTG_CC_ManualIsolation',
      system_Recirculation: 'EH_HTG_CC_SystemRecirculation',
      control_System: 'EH_HTG_CC_ControlSystem',
      frame_Type: 'EH_HTG_CC_FrameType',
      package_Supports: 'EH_HTG_CC_PackageSupports',
      panel_Location: 'EH_HTG_CC_PanelLocation',
      extras: 'EH_HTG_CC_Extras',
      integrity_Test: 'EH_HTG_CC_IntegrityTest',
      data_Collection: 'EH_HTG_CC_DataCollection',
      communications: 'EH_HTG_CC_Communications',
      service_Offering: 'EH_HTG_CC_ServiceOffering',
      pressure_Drop: 'EH_HTG_CC_PressureDrop',
      controlValveFeedback: 'EH_HTG_CC_ControlValveFeedback',
      FA11: 'EH_HTG_CC_FA11',
      PA31: 'EH_HTG_CC_PA31',
      TA11: 'EH_HTG_CC_TA11',
      TA31: 'EH_HTG_CC_TA31',
      TA41: 'EH_HTG_CC_TA41',
      TA01: 'EH_HTG_CC_TA01',
      UPS: 'EH_HTG_CC_UPS',
      insulation: 'EH_HTG_CC_Insulation',
    },
    {
      //EH HTG Dual Control Options
      name: 'EH_HTG_DC',
      gasket_Material: 'EH_HTG_DC_GasketMaterial',
      actuation: 'EH_HTG_DC_Actuator',
      condensate_Removal: 'EH_HTG_DC_CondensateRemoval',
      high_Limit: 'EH_HTG_DC_HighLimit',
      man_Isolation: 'EH_HTG_DC_ManualIsolation',
      system_Recirculation: 'EH_HTG_DC_SystemRecirculation',
      control_System: 'EH_HTG_DC_ControlSystem',
      frame_Type: 'EH_HTG_DC_FrameType',
      package_Supports: 'EH_HTG_DC_PackageSupports',
      panel_Location: 'EH_HTG_DC_PanelLocation',
      extras: 'EH_HTG_DC_Extras',
      integrity_Test: 'EH_HTG_DC_IntegrityTest',
      data_Collection: 'EH_HTG_DC_DataCollection',
      communications: 'EH_HTG_DC_Communications',
      service_Offering: 'EH_HTG_DC_ServiceOffering',
      pressure_Drop: 'EH_HTG_DC_PressureDrop',
      controlValveFeedback: 'EH_HTG_DC_ControlValveFeedback',
      FA11: 'EH_HTG_DC_FA11',
      PA31: 'EH_HTG_DC_PA31',
      TA11: 'EH_HTG_DC_TA11',
      TA31: 'EH_HTG_DC_TA31',
      TA41: 'EH_HTG_DC_TA41',
      TA01: 'EH_HTG_DC_TA01',
      UPS: 'EH_HTG_DC_UPS',
      insulation: 'EH_HTG_DC_Insulation',
    },
    {
      //EH HTG Steam Control Options
      name: 'EH_HTG_SC',
      gasket_Material: 'EH_HTG_SC_GasketMaterial',
      actuation: 'EH_HTG_SC_Actuator',
      condensate_Removal: 'EH_HTG_SC_CondensateRemoval',
      high_Limit: 'EH_HTG_SC_HighLimit',
      man_Isolation: 'EH_HTG_SC_ManualIsolation',
      system_Recirculation: 'EH_HTG_SC_SystemRecirculation',
      control_System: 'EH_HTG_SC_ControlSystem',
      frame_Type: 'EH_HTG_SC_FrameType',
      package_Supports: 'EH_HTG_SC_PackageSupports',
      panel_Location: 'EH_HTG_SC_PanelLocation',
      extras: 'EH_HTG_SC_Extras',
      integrity_Test: 'EH_HTG_SC_IntegrityTest',
      data_Collection: 'EH_HTG_SC_DataCollection',
      communications: 'EH_HTG_SC_Communications',
      service_Offering: 'EH_HTG_SC_ServiceOffering',
      pressure_Drop: 'EH_HTG_SC_PressureDrop',
      controlValveFeedback: 'EH_HTG_SC_ControlValveFeedback',
      FA11: 'EH_HTG_SC_FA11',
      PA31: 'EH_HTG_SC_PA31',
      TA11: 'EH_HTG_SC_TA11',
      TA31: 'EH_HTG_SC_TA31',
      TA41: 'EH_HTG_SC_TA41',
      TA01: 'EH_HTG_SC_TA01',
      UPS: 'EH_HTG_SC_UPS',
      insulation: 'EH_HTG_SC_Insulation',
    },
    {
      name: 'empty',
      gasket_Material: '',
      //global_Variance:'',
      actuation: '',
      condensate_Removal: '',
      high_Limit: '',
      man_Isolation: '',
      system_Recirculation: '',
      control_System: '',
      frame_Type: '',
      package_Supports: '',
      panel_Location: '',
      extras: '',
      integrity_Test: '',
      data_Collection: '',
      communications: '',
      service_Offering: '',
      pressure_Drop: '',
      controlValveFeedback: '',
      FA11: '',
      PA31: '',
      TA11: '',
      TA31: '',
      TA41: '',
      TA01: '',
      UPS: '',
      insulation: '',
    }
  ];

  setActualSecondaryPressureDropUnit(globalVariance: string) {
    if (globalVariance == 'AS/UL') {
      this.actualSecondaryPressureDropUnit = 'Psi'
    } else this.actualSecondaryPressureDropUnit = 'kPa'
  }


  displaySizingResults(data: EasiheatGen4Output) {
    for (let x = 0; x < data.easiHeatGen4Units.length; x++) {

      var side = x == 0 ? 'left' : 'right';

      if (isNullOrUndefined(data.easiHeatGen4Units[x].unitModel)) {
        this.resultsForm.get(`${side}`).disable();
        this.resultsForm.get(`${side == 'left' ? 'right' : 'left'}.pricingOptions.unitSelected`).patchValue(true, { emitEvent: true });
        this.resultsForm.get(`${side == 'left' ? 'right' : 'left'}.pricingOptions.unitSelected`).disable({ emitEvent: false });
        continue;
      }

      this.unitTypeHeaderName[x] = this.getAppTypeHeaderName(data.easiHeatGen4Units[x].unitModel.substring(3, 5));
      this.isCC[x] = data.easiHeatGen4Units[x].unitModel.includes('EHHCC');
      this.resultsForm.get(`${side}.easiHeatUnit`).patchValue(data.easiHeatGen4Units[x].unitModel)
      this.resultsForm.get(`${side}.trimType`).patchValue(data.easiHeatGen4Units[x].trimType !== null ? data.easiHeatGen4Units[x].trimType.length > 0 ? data.easiHeatGen4Units[x].trimType.toUpperCase() : 'N/A' : 'N/A')
      this.resultsForm.get(`${side}.lenght`).patchValue(data.easiHeatGen4Units[x].length)
      this.resultsForm.get(`${side}.height`).patchValue(data.easiHeatGen4Units[x].height)
      this.resultsForm.get(`${side}.width`).patchValue(data.easiHeatGen4Units[x].width)
      this.resultsForm.get(`${side}.weight`).patchValue(data.easiHeatGen4Units[x].weight)

      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // Save Valves Sizes in both: Metric and Imperial units and Display Only Appropriate one in relation to Global Variance //////
      this.resultsForm.get(`${side}.controlValveSizeDn`).patchValue(data.easiHeatGen4Units[x].steamCvSize)
      this.resultsForm.get(`${side}.controlValveSizeAnsi`).patchValue(data.easiHeatGen4Units[x].steamCvSizeAnsi)
      this.resultsForm.get(`${side}.condensateValveSizeDn`).patchValue(data.easiHeatGen4Units[x].condensateValveDnSize)
      this.resultsForm.get(`${side}.condensateValveSizeAnsi`).patchValue(data.easiHeatGen4Units[x].condensateValveAnsiSize)

      if (this.globalVariance !== 'AS/UL') {
        this.resultsForm.get(`${side}.controlValveSizeDisplay`).patchValue(data.easiHeatGen4Units[x].steamCvSize)
        this.resultsForm.get(`${side}.condensateValveSizeDisplay`).patchValue(data.easiHeatGen4Units[x].condensateValveDnSize)
      }
      else {
        this.resultsForm.get(`${side}.controlValveSizeDisplay`).patchValue(data.easiHeatGen4Units[x].steamCvSizeAnsi)
        this.resultsForm.get(`${side}.condensateValveSizeDisplay`).patchValue(data.easiHeatGen4Units[x].condensateValveAnsiSize)
      }
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      this.resultsForm.get(`${side}.heatExchangePlateType`).patchValue(data.easiHeatGen4Units[x].heatExchangerPlateType)
      this.resultsForm.get(`${side}.heFrameType`).patchValue(data.easiHeatGen4Units[x].heFrameType)
      this.resultsForm.get(`${side}.epdmwGasketPressureCheck`).patchValue(data.easiHeatGen4Units[x].epdmwGasketPressureCheck)
      this.resultsForm.get(`${side}.numberOfPlates`).patchValue(data.easiHeatGen4Units[x].numberOfPlates)

      this.resultsForm.get(`${side}.steamCvKv`).patchValue(data.easiHeatGen4Units[x].steamCvKv)
      this.resultsForm.get(`${side}.steamCvCalculatedNoiseLevel`).patchValue(data.easiHeatGen4Units[x].steamCvCalculatedNoiseLevel !== 0 ? (((data.easiHeatGen4Units[x].steamCvCalculatedNoiseLevel).toFixed(2)) + ' dBA') : '---')

      this.resultsForm.get(`${side}.condensateValveTrim`).patchValue(data.easiHeatGen4Units[x].condensateValveTrim)
      this.resultsForm.get(`${side}.condensateValveKv`).patchValue(data.easiHeatGen4Units[x].condensateValveKv)
      this.resultsForm.get(`${side}.channelTypeSide1`).patchValue(data.easiHeatGen4Units[x].channelTypeSide1)
      this.resultsForm.get(`${side}.channelTypeSide2`).patchValue(data.easiHeatGen4Units[x].channelTypeSide2)
      this.resultsForm.get(`${side}.subCooling`).patchValue(data.easiHeatGen4Units[x].subCooling)

      convertToDefaultValueInPageUnits("actualSecondaryPressureDrop", data.easiHeatGen4Units[x].secondaryPressureDrop, this.globalVariance == 'AS/UL' ? '45' : '39', this.unitsService, this.resultsForm, side);
      convertToDefaultValueInPageUnits("maximumCondensateTemperature", 95, this.temperatureUnit.preference.value, this.unitsService, this.resultsForm, side);//this should be 203F if global variance US but there will be more changes to implement once they decided what they actually want here so so far it is what it is
      this.setActualSecondaryPressureDropUnit(this.globalVariance)

      this.resultsForm.get(`${side}.pricingOptions.unit_and_eh_internal_SSP`).patchValue(data.easiHeatGen4Units[x].unitPrice + data.easiHeatGen4Units[x].hePrice);
      this.resultsForm.get(`${side}.pricingOptions.unit_and_eh_internal_SellingPrice`).patchValue((data.easiHeatGen4Units[x].unitPrice + data.easiHeatGen4Units[x].hePrice) * +this.modPref.EHgen4_SellingMarkup);
      this.resultsForm.get(`${side}.pricingOptions.delivery`).patchValue(this.modPref.EHgen4_DeliveryCost);


      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //This if statement check is to ensure that the correct nomenclature is going to be displayed if user is loading a saved Job.
      //The Sizing Api Object Nomenclature value is not the one that is displayed on the screen.The correct one is coming from Pricing Api object as it is accounting all the unit option settings.
      //The sizing one is only generated for the purpose to be pass to Pricing Api as a base one that then is going to be adjusted with all the unit options changes. 
      if (!this.isLoadingJob) {
        this.resultsForm.get(`${side}.unitOptions.nomenclature`).patchValue(data.easiHeatGen4Units[x].nomenclature);
      }
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


      //set Actual Condensate Removal Option Value
      this.actualCondensateRemovalValue[x] = data.easiHeatGen4Units[x].condensateRemoval == 'PTHC' ? 'PT' : data.easiHeatGen4Units[x].condensateRemoval;

      //need the steamCvSplitRangeKv to determin if unit CV is a split range or not. (result affects Uits Rules).
      this.resultsForm.get(`${side}.steamCvSplitRangeKv`).patchValue(data.easiHeatGen4Units[x].steamCvSplitRangeKv);


      //temporary For Rob Dean
      let calcs =
        'Steam Cv Required Kv:' + "\n" +
        data.easiHeatGen4Units[x].steamCvRequiredKv + "\n" +
        ' ' + "\n" +
        'Condensate Valve Required Kv:' + "\n" +
        data.easiHeatGen4Units[x].condensateValveRequiredKv + "\n" +
        ' ' + "\n" +
        'Valve Exit velocity M/S:' + "\n" +
        data.easiHeatGen4Units[x].steamValveCalculatedExitVelocityMetersPerSecond + "\n" +
        ' ' + "\n" +
        'Steam Cv Inlet Density:' + "\n" +
        data.easiHeatGen4Units[x].steamCvInletDensity + "\n" +
        ' ' + "\n" +
        'Steam Cv Outlet Density:' + "\n" +
        data.easiHeatGen4Units[x].steamCvOutletDensity;

      if (side == 'left') {
        this.firstSelectedUnitCalcs = calcs;
      } else {
        this.secondSelectedUnitCalcs = calcs;
      }


    }

  }

  displayPricingResults(data: EHUnitPricingOutput[]) {

    for (let x = 0; x < data.length; x++) {

      var side = x == 0 ? 'left' : 'right';

      //set the correct side if one result only 
      if (data.length == 1) {
        if (data[x].nomenclature.substring(3, 5) != 'SC') {
          side = 'right'
        }
      }

      if (this.globalVariance !== 'AS/UL') {
        this.resultsForm.get(`${side}.packageInletConnectionSize`).patchValue(data[x].inletSize);
        this.resultsForm.get(`${side}.condensatePipeSize`).patchValue(data[x].condensatePipeSize);
        this.resultsForm.get(`${side}.secondaryInletOutletPipeSize`).patchValue(data[x].inletOutletPipeSize);
      }
      else {
        this.resultsForm.get(`${side}.packageInletConnectionSize`).patchValue(data[x].inletSizeAnsi);
        this.resultsForm.get(`${side}.condensatePipeSize`).patchValue(data[x].condensatePipeSizeAnsi);
        this.resultsForm.get(`${side}.secondaryInletOutletPipeSize`).patchValue(data[x].inletOutletPipeSizeAnsi);

      }

      var unit_and_eh_internal_SSP = +this.resultsForm.get(`${side}.pricingOptions.unit_and_eh_internal_SSP`).value;
      var unit_and_eh_internal_SellingPrice = +this.resultsForm.get(`${side}.pricingOptions.unit_and_eh_internal_SellingPrice`).value;

      this.resultsForm.get(`${side}.pricingOptions.ssp`).patchValue(+((unit_and_eh_internal_SSP + (data[x].totalSSPPrice / this.modPref.EHgen4_CurrencyConversion)).toFixed(0)));
      this.resultsForm.get(`${side}.pricingOptions.sellingPrice`).patchValue(+((unit_and_eh_internal_SellingPrice + ((data[x].totalSSPPrice / this.modPref.EHgen4_CurrencyConversion)) * +this.modPref.EHgen4_SellingMarkup)).toFixed(0));
      this.resultsForm.get(`${side}.unitOptions.condensateRemoval`).patchValue(this.actualCondensateRemovalValue[x], { emitEvent: false });
      //this.setCondensateRemoval(this.actualCondensateRemovalValue);
      this.resultsForm.get(`${side}.unitOptions.nomenclature`).patchValue(data[x].nomenclature, { emitEvent: false });

      if (this.serviceOfferingKey.length !== data.length && this.selectedEnumerationNames.service_Offering[x] !== "" && this.isLoadingJob === false) {
        var so = { selectedValue: this.translationService.displayGroup.enumerations.filter(mp => mp.opCoOverride === false && mp.enumerationName === this.selectedEnumerationNames.service_Offering[x])[0].enumerationDefinitions[0].value }
        this.calculateServiceOfferingPrice(so, x);
      }

      if (!this.isLoadingJob) {
        this.onUnitOptionChange('fa11', data[x].fa11Possible, side);
      }



      if (data[x].nomenclature != null) {
        this.calculateTotalPrice(side);
      }


      //this.setQuotationValidationMessage(new Date);

      // Finally, calculate selling markup/gross margin. However, before calculating these figures, first check if this is initial calc or any of these fields have been manually updated? If so then re-calculate them accordingly.

      if (this.resultsForm.get(`${side}.pricingOptions.sellingMarkupUpdated`).value == false && this.resultsForm.get(`${side}.pricingOptions.sellingMarkupUpdated`).value == false) {
        this.calculateSellingMarkup(side);
        this.calculateGrossMargin(side);
      }
      else if (this.resultsForm.get(`${side}.pricingOptions.sellingMarkupUpdated`).value == true) {
        this.calculateSellingPriceFromSellingMarkup(this.resultsForm.get(`${side}.pricingOptions.markup`).value, side);
      }
      else {
        this.calculateSellingPriceFromGrossMargin(this.resultsForm.get(`${side}.pricingOptions.grossMargin`).value, side);
      }
    }

    if (this.initilaPricing && !this.isLoadingJob) {
      this.applyUnitsOptionsRules('both');
      setTimeout(() => {
        this.unitOptionChanged.emit(this.getAllUnitOptionsValue('both'));
      }, 350);

      this.initilaPricing = false;
    }
  }


  displayEnergySavingResults(data: EnergySavingOutput) {

    //Fuel Info
    this.fuelKcalValue = data.fuelCalorificValue.toString();
    this.fuelKcalUnit = data.fuelCalorificValueUnit == 415 ? this.translatePipe.transform('UNIT_J_FSLASH_KG', false) : this.translatePipe.transform('UNIT_J_FSLASH_M3', false);
    this.fuelCO2eValue = data.fuelCO2eEmissions.toString();
    this.fuelCO2eUnit = data.fuelCO2eEmmissionUnit == 417 ? this.translatePipe.transform('UNIT_KG_FSLASH_GJ', false) : data.fuelCO2eEmmissionUnit == 418 ? this.translatePipe.transform('UNIT_LB_FSLASH_GJ', false) : data.fuelCO2eEmmissionUnit == 411 ? this.translatePipe.transform('UNIT_KG_FSLASH_MMBTU', false) : data.fuelCO2eEmmissionUnit == 412 ? this.translatePipe.transform('UNIT_LB_FSLASH_MMBTU', false) : this.translatePipe.transform('UNKNOWN_UNIT_TYPE', false);

    //first fill the form 
    for (let x = 0; x < data.easiHeatGen4EnergySavingUnits.length; x++) {

      var side = x == 0 ? 'left' : 'right';


      if (isNullOrUndefined(data.easiHeatGen4EnergySavingUnits[x].unitType)) {
        continue;
      }
      //set the correct side if one result only 
      if (data.easiHeatGen4EnergySavingUnits.length == 1) {
        if (data.easiHeatGen4EnergySavingUnits[x].unitType != 'SC') {
          side = 'right'
        }
      }

      this.resultsForm.get(`${side}.energySavings.unitType`).patchValue(data.easiHeatGen4EnergySavingUnits[x].unitType.toString());
      this.resultsForm.get(`${side}.energySavings.steamConsumptionReductionPercent`).patchValue(data.easiHeatGen4EnergySavingUnits[x].steamConsumptionReductionPercent.toString());
      this.resultsForm.get(`${side}.energySavings.steamMassSaved`).patchValue(data.easiHeatGen4EnergySavingUnits[x].steamMassSaved.toString());
      this.resultsForm.get(`${side}.energySavings.co2eEmmissionReductionPerYear`).patchValue(data.easiHeatGen4EnergySavingUnits[x].co2eEmmissionReductionPerYear.toString());
      this.resultsForm.get(`${side}.energySavings.co2eEmmissionCostReductionPerYear`).patchValue(data.easiHeatGen4EnergySavingUnits[x].co2eEmmissionCostReductionPerYear.toString());
      this.resultsForm.get(`${side}.energySavings.energySavedPerYear`).patchValue(data.easiHeatGen4EnergySavingUnits[x].energySavedPerYear.toString());
      this.resultsForm.get(`${side}.energySavings.energyCostReductionPerYear`).patchValue(data.easiHeatGen4EnergySavingUnits[x].energyCostReductionPerYear.toString());
      this.resultsForm.get(`${side}.energySavings.waterSavedPerYear`).patchValue(data.easiHeatGen4EnergySavingUnits[x].waterSavedPerYear.toString());
      this.resultsForm.get(`${side}.energySavings.waterCostReductionPerYear`).patchValue(data.easiHeatGen4EnergySavingUnits[x].waterCostReductionPerYear.toString());
      this.resultsForm.get(`${side}.energySavings.totalEvcCostReductionPerYear`).patchValue(data.easiHeatGen4EnergySavingUnits[x].totalEvcCostReductionPerYear.toString());
        
      // UI Display Grid
      // Values left
      if (side === 'left') {
        this.leftUnitType_Value = data.easiHeatGen4EnergySavingUnits[x].unitType.toString();
        this.leftSteamConsumptionReductionPercent_Value = +data.easiHeatGen4EnergySavingUnits[x].steamConsumptionReductionPercent.toFixed(0);
        this.leftSteamMassSaved_Value = +data.easiHeatGen4EnergySavingUnits[x].steamMassSaved.toFixed(0);
        this.leftCo2eEmissionReductionPerYear_Value = +data.easiHeatGen4EnergySavingUnits[x].co2eEmmissionReductionPerYear.toFixed(0);
        this.leftCo2eEmissionCostReductionPerYear_Value = +data.easiHeatGen4EnergySavingUnits[x].co2eEmmissionCostReductionPerYear.toFixed(0);
        this.leftEnergySavedPerYear_Value = +data.easiHeatGen4EnergySavingUnits[x].energySavedPerYear.toFixed(0);
        this.leftEnergyCostReductionPerYear_Value = +data.easiHeatGen4EnergySavingUnits[x].energyCostReductionPerYear.toFixed(0);
        this.leftWaterSavedPerYear_Value = +data.easiHeatGen4EnergySavingUnits[x].waterSavedPerYear.toFixed(0);
        this.leftWaterCostReductionPerYear_Value = +data.easiHeatGen4EnergySavingUnits[x].waterCostReductionPerYear.toFixed(0);
        this.leftTotalEvcCostReductionPerYear_Value = +data.easiHeatGen4EnergySavingUnits[x].totalEvcCostReductionPerYear.toFixed(0);
      }

      if (side === 'right') {
        //Values right
        this.rightUnitType_Value = data.easiHeatGen4EnergySavingUnits[x].unitType.toString();
        this.rightSteamConsumptionReductionPercent_Value = +data.easiHeatGen4EnergySavingUnits[x].steamConsumptionReductionPercent.toFixed(0);
        this.rightSteamMassSaved_Value = +data.easiHeatGen4EnergySavingUnits[x].steamMassSaved.toFixed(0);
        this.rightCo2eEmissionReductionPerYear_Value = +data.easiHeatGen4EnergySavingUnits[x].co2eEmmissionReductionPerYear.toFixed(0);
        this.rightCo2eEmissionCostReductionPerYear_Value = +data.easiHeatGen4EnergySavingUnits[x].co2eEmmissionCostReductionPerYear.toFixed(0);
        this.rightEnergySavedPerYear_Value = +data.easiHeatGen4EnergySavingUnits[x].energySavedPerYear.toFixed(0);
        this.rightEnergyCostReductionPerYear_Value = +data.easiHeatGen4EnergySavingUnits[x].energyCostReductionPerYear.toFixed(0);
        this.rightWaterSavedPerYear_Value = +data.easiHeatGen4EnergySavingUnits[x].waterSavedPerYear.toFixed(0);
        this.rightWaterCostReductionPerYear_Value = +data.easiHeatGen4EnergySavingUnits[x].waterCostReductionPerYear.toFixed(0);
        this.rightTotalEvcCostReductionPerYear_Value = +data.easiHeatGen4EnergySavingUnits[x].totalEvcCostReductionPerYear.toFixed(0);
      }
    }

  }


  setupDefaultOptionsForSizingSelection(sizedUnitTypes: string[]) {
    this.selectedEnumerationNames = new EnumerationNames();
    for (let i = 0; i < sizedUnitTypes.length; i++) {

      this.selectedEnumerationNames.gasketMaterial.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).gasket_Material);
      this.selectedEnumerationNames.actuator.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).actuation);
      this.selectedEnumerationNames.condensateRemoval.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).condensate_Removal);
      this.selectedEnumerationNames.highLimit.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).high_Limit)
      this.selectedEnumerationNames.manualIsolation.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).man_Isolation)
      this.selectedEnumerationNames.systemRecirculation.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).system_Recirculation)
      this.selectedEnumerationNames.controlSystem.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).control_System)
      this.selectedEnumerationNames.frameType.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).frame_Type)
      this.selectedEnumerationNames.packageSupports.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).package_Supports)
      this.selectedEnumerationNames.panelLocation.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).panel_Location)
      this.selectedEnumerationNames.extras.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).extras)
      this.selectedEnumerationNames.integrityTest.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).integrity_Test)
      this.selectedEnumerationNames.dataCollection.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).data_Collection)
      this.selectedEnumerationNames.communications.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).communications)
      this.selectedEnumerationNames.service_Offering.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).service_Offering)
      this.selectedEnumerationNames.pressure_Drop.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).pressure_Drop)
      this.selectedEnumerationNames.controlValveFeedback.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).controlValveFeedback)
      this.selectedEnumerationNames.fa11.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).FA11)
      this.selectedEnumerationNames.pa31.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).PA31)
      this.selectedEnumerationNames.ta11.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).TA11)
      this.selectedEnumerationNames.ta31.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).TA31)
      this.selectedEnumerationNames.ta41.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).TA41)
      this.selectedEnumerationNames.ta01.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).TA01)
      this.selectedEnumerationNames.ups.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).UPS)
      this.selectedEnumerationNames.insulation.splice(i, 0, this.hashTable.find(m => m.name === sizedUnitTypes[i]).insulation)
    }
  }


  setCondensateRemoval(crOptions: string[]) {

    for (let x = 0; x < crOptions.length; x++) {

      if (crOptions[x] !== 'N') {
        this.resultsForm.get(`${x == 0 ? 'left' : 'right'}.unitOptions.condensateRemoval`).patchValue(crOptions[x], { emitEvent: false });
      }
      else {
        this.condensateRemoval_FilterLists[x] = ['N']
        this.resultsForm.get(`${x == 0 ? 'left' : 'right'}.unitOptions.condensateRemoval`).patchValue('N', { emitEvent: false });
      }
    }
  }

  blockKeyDownFunction(event) {
    return false
  }

  onServiceOfferingLoad(event: any, index: number) {
    if (!this.isLoadingJob) {
      this.calculateServiceOfferingPrice(event, index);
    }
  }

  onServiceOfferingChange(event: any, index: number) {
    //this.resetLoadingJobStatus();
    this.calculateServiceOfferingPrice(event, index);
  }

  /**
   * Method to select service offerings cost based on service offering selections.
  */
  calculateServiceOfferingPrice(event: any, index: number) {
    var side = index == 0 ? 'left' : 'right'
    switch (event.selectedValue) {
      case "No Service Offering":
        this.resultsForm.get(`${side}.pricingOptions.serviceOfferingPrice`).patchValue(0, { emitEvent: false });
        this.resultsForm.get(`${side}.pricingOptions.serviceOfferingFieldLabel`).patchValue(this.translatePipe.transform('NO_SERVICE_OFFERING', false), { emitEvent: false });
        this.serviceOfferingKey[index] = this.resultsForm.get(`${side}.pricingOptions.serviceOfferingFieldLabel`).value;
        break;
      case "Commission Only":
        this.resultsForm.get(`${side}.pricingOptions.serviceOfferingPrice`).patchValue(+this.modPref.EHgen4_Commission, { emitEvent: false });
        this.resultsForm.get(`${side}.pricingOptions.serviceOfferingFieldLabel`).patchValue(this.translatePipe.transform('COMMISSION_ONLY', false), { emitEvent: false });
        this.serviceOfferingKey[index] = this.resultsForm.get(`${side}.pricingOptions.serviceOfferingFieldLabel`).value;
        break;
      case "Extended Warranty Year 1":
        this.resultsForm.get(`${side}.pricingOptions.serviceOfferingPrice`).patchValue(+this.modPref.EHgen4_YearOne, { emitEvent: false });
        this.resultsForm.get(`${side}.pricingOptions.serviceOfferingFieldLabel`).patchValue(this.translatePipe.transform('1_YEAR_EXTENDED_WARRANTY', false), { emitEvent: false });
        this.serviceOfferingKey[index] = this.resultsForm.get(`${side}.pricingOptions.serviceOfferingFieldLabel`).value;
        break;
      case "Extended Warranty Year 2":
        this.resultsForm.get(`${side}.pricingOptions.serviceOfferingPrice`).patchValue(+this.modPref.EHgen4_YearTwo, { emitEvent: false });
        this.resultsForm.get(`${side}.pricingOptions.serviceOfferingFieldLabel`).patchValue(this.translatePipe.transform('2_YEAR_EXTENDED_WARRANTY', false), { emitEvent: false });
        this.serviceOfferingKey[index] = this.resultsForm.get(`${side}.pricingOptions.serviceOfferingFieldLabel`).value;
        break;
      case "Extended Warranty Year 3":
        this.resultsForm.get(`${side}.pricingOptions.serviceOfferingPrice`).patchValue(+this.modPref.EHgen4_YearThree, { emitEvent: false });
        this.resultsForm.get(`${side}.pricingOptions.serviceOfferingFieldLabel`).patchValue(this.translatePipe.transform('3_YEAR_EXTENDED_WARRANTY', false), { emitEvent: false });
        this.serviceOfferingKey[index] = this.resultsForm.get(`${side}.pricingOptions.serviceOfferingFieldLabel`).value;
        break;
    }
    // Update total price.
    this.calculateTotalPrice(side);
  }

  /*
 * Method to calculate the total price.
 */
  calculateTotalPrice(side: string) {


    let sellingPrice = +this.resultsForm.get(`${side}.pricingOptions.sellingPrice`).value;
    let serviceOfferingPrice = +this.resultsForm.get(`${side}.pricingOptions.serviceOfferingPrice`).value;
    let deliveryCost = +this.modPref.EHgen4_DeliveryCost;

    if (this.resultsForm.get(`${side}.easiHeatUnit`).value != null) {
      var TotalPrice = sellingPrice + serviceOfferingPrice + deliveryCost;
      this.resultsForm.get(`${side}.pricingOptions.totalPrice`).patchValue(+(TotalPrice.toFixed(0)));
    }

    //if (!this.isLoadingJob && this.sizingModuleForm.dirty && this.quoteExpired) { // force repricing
    //  //debugger;
    //  this.quoteExpired = false;
    //  this.calculatePrice();
    //}
  }

  /*
  * Method to update Selling price on Selling Markup change.
  */
  onSellingPriceChange(side: string) {
    // Update the appropriate flags.
    this.resultsForm.get(`${side}.pricingOptions.sellingPriceUpdated`).patchValue(true, { emitEvent: false });
    this.resultsForm.get(`${side}.pricingOptions.sellingMarkupUpdated`).patchValue(false, { emitEvent: false });
    this.resultsForm.get(`${side}.pricingOptions.grossMarginUpdated`).patchValue(false, { emitEvent: false });

    this.calculateGrossMarginAndSellingMarkupFromSellingPrice(side);

    //this.scrollToElement(this.priceResultsContent, "start");
  }

  /*
  * Method to update Selling price on Selling Markup change.
  */
  onSellingMarkupChange(value: any, side: string) {
    // Update the appropriate flags.
    this.resultsForm.get(`${side}.pricingOptions.sellingMarkupUpdated`).patchValue(true, { emitEvent: false });
    this.resultsForm.get(`${side}.pricingOptions.grossMarginUpdated`).patchValue(false, { emitEvent: false });
    this.resultsForm.get(`${side}.pricingOptions.sellingPriceUpdated`).patchValue(false, { emitEvent: false });

    this.calculateSellingPriceFromSellingMarkup(value, side);

    //this.scrollToElement(this.priceResultsContent, "start");
  }

  /*
  * Method to update Selling price on Gross Margin change.
  */
  onGrossMarginChange(value: any, side: string) {
    // Update the appropriate flags.
    this.resultsForm.get(`${side}.pricingOptions.sellingMarkupUpdated`).patchValue(false, { emitEvent: false });
    this.resultsForm.get(`${side}.pricingOptions.grossMarginUpdated`).patchValue(true, { emitEvent: false });
    this.resultsForm.get(`${side}.pricingOptions.sellingPriceUpdated`).patchValue(false, { emitEvent: false });

    this.calculateSellingPriceFromGrossMargin(value, side);

    //this.scrollToElement(this.priceResultsContent, "start");
  }

  calculateSellingMarkup(side: string) {

    var ssp = this.resultsForm.get(`${side}.pricingOptions.ssp`).value;
    var sellingPrice = this.resultsForm.get(`${side}.pricingOptions.sellingPrice`).value;

    if (ssp > 0 && sellingPrice > 0) {
      var markup = (sellingPrice / ssp).toFixed(2);
      this.resultsForm.get(`${side}.pricingOptions.markup`).patchValue(+markup, { emitEvent: false });
    }
  }

  calculateGrossMargin(side: string) {

    var ssp = this.resultsForm.get(`${side}.pricingOptions.ssp`).value;
    var sellingPrice = this.resultsForm.get(`${side}.pricingOptions.sellingPrice`).value;

    if (ssp > 0 && sellingPrice > 0) {

      var margin = (((sellingPrice - ssp) / sellingPrice) * 100).toFixed(2)
      this.resultsForm.get(`${side}.pricingOptions.grossMargin`).patchValue(+margin, { emitEvent: false });
    }

  }

  calculateSellingPriceFromSellingMarkup(value: any, side: string) {

    if (this.resultsForm.get(`${side}.pricingOptions.ssp`).value > 0 && value && value > 0) {
      this.resultsForm.get(`${side}.pricingOptions.sellingPrice`).patchValue(+((this.resultsForm.get(`${side}.pricingOptions.ssp`).value * value).toFixed(0)), { emitEvent: false });


      // Update gross margin.
      this.calculateGrossMargin(side);
    }

    this.calculateTotalPrice(side);
  }

  calculateGrossMarginAndSellingMarkupFromSellingPrice(side: string) {

    // Update gross margin
    this.calculateGrossMargin(side);

    // Update selling markup.
    this.calculateSellingMarkup(side);


    this.calculateTotalPrice(side);
  }

  calculateSellingPriceFromGrossMargin(value: any, side: string) {

    if (this.resultsForm.get(`${side}.pricingOptions.ssp`).value > 0 && value && value > 0) {

      if (value >= 100) {

        this.resultsForm.get(`${side}.pricingOptions.sellingPrice`).patchValue(+((this.resultsForm.get(`${side}.pricingOptions.ssp`).value * (value / 100)).toFixed(0)), { emitEvent: false });

      }
      else {
        this.resultsForm.get(`${side}.pricingOptions.sellingPrice`).patchValue(+(((this.resultsForm.get(`${side}.pricingOptions.ssp`).value / (100 - value)) * 100).toFixed(0)), { emitEvent: false });

      }

      // Update selling markup.
      this.calculateSellingMarkup(side);
    }

    this.calculateTotalPrice(side);
  }

  applyUnitsOptionsRules(side: string, option?: string) {

    /*************************************************************************
     *!!!!!!!!!!!!!!!!!!!!!!!!!!!!! VERY IMPORTANT !!!!!!!!!!!!!!!!!!!!!!!!!!!

     If you are going to add new rule or change existing one please make sure
                          to do same thing in the method

                setUnitsOptionsRulesToModulePrefBeforeInitialPricingCall

     *************************************************************************/

    /*
     * PLEASE NOTE
     * that every side has additional isNullOrUndefined check in case if there is only one Unit that has been sizied.
     */

    //Process Coditions
    const pc: ProcessConditionsFormGroup = this.processConditions;
    //SizingResultLeft
    const lt: EasiHeatUnitFormGroup = this.resultsForm.get('left').value;
    //SizingResultRight
    const rt: EasiHeatUnitFormGroup = this.resultsForm.get('right').value;

    //HEATING (HTG)///////////////////////////////////////////////////////////
    /*************************************************************************
     *EHHDC*
     *******/
    if (!isNullOrUndefined(rt.easiHeatUnit) && (side == 'right' || side == 'both')) {
      if (rt.easiHeatUnit.substr(0, 5) == 'EHHDC') {

        let cvSize = rt.easiHeatUnit.substr(5, 6);

        //Gasket Material Rule
        //Heat Seal F (G2,G5) is only available for the TS6 from alfa laval
        //FYI: G5 is achieved by selecting G2 and then selecting "Allow High Design Pressure" check box in the UI
        if (!rt.heatExchangePlateType.includes('TS6')) {
          this.gasketMaterial_FilterLists[1] = ['G1']; //there is no need to include G3 in here as it's not available on any HTG units and this rule has been implemented at the DB level. //G3 G6 RULE --> WRAS is not required for HTG systems so G3 and G6 are not included in any HTG systems
        } else {
          this.gasketMaterial_FilterLists[1] = ['G1', 'G2'];
        }

        //Actuation Rule 3 + designPressure12Bar rule
        if (cvSize == '5') {
          if (this.convertedToBargPressure > 8.8 || (pc.designPressure12Bar && this.rightHighLimit.internalValue == 'N')) {
            this.actuator_FilterLists[1] = ['PN'];
          }
          else if (this.convertedToBargPressure <= 8.8 && (pc.designPressure12Bar && this.rightHighLimit.internalValue != 'N')) {
            this.actuator_FilterLists[1] = [];
          }
        }
        if (cvSize == '6') {

          //Actuation Rule 4 + designPressure12Bar rule
          if (this.convertedToBargPressure > 5.3 || pc.designPressure12Bar) {
            this.actuator_FilterLists[1] = ['PN'];

            //High Limit Rule 4 - it must sits with in Actuation Rule 4 + designPressure12Bar rule
            if (pc.designPressure12Bar) {
              this.highLimit_FilterLists[1] = ['SIHL']
            }
            else if (this.convertedToBargPressure > 6.9) {
              this.highLimit_FilterLists[1] = ['N', 'SIHL']
            }
          }
        }
        //Manual Isolation Rules
        if (cvSize == '5') {
          this.manualIsolation_FilterLists[1] = ['N', 'V2'];
        }
        if (cvSize == '6') {
          this.manualIsolation_FilterLists[1] = ['N', 'V1', 'V2'];
        }
        //High Limit Rule 3
        if (cvSize == '6') {
          if (this.convertedToBargPressure > 6.9) {
            if (this.rightActuator.internalValue == 'EL') {
              this.highLimit_FilterLists[1] = ['N']
              this.rightHighLimit.internalValue = 'N'
            }
          }
        }


        //////////////////////////////////
        // Actuation Rules under HTG DC //
        //////////////////////////////////
        //  Actuation set to EL //
        //////////////////////////
        if (this.rightActuator.internalValue == 'EL') {

          this.controlValveFeedback_FilterLists[1] = ['CVF']
          this.resultsForm.get(`right.unitOptions.controlValveFeedback`).patchValue(this.controlValveFeedback_FilterLists[1][0], { emitEvent: false });

          this.extras_FilterLists[1] = ['N', 'GP'];
          if (!isUndefined(this.rightExtras.enumeration)) {
            if (this.extras_FilterLists[1].includes(this.rightExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value)) {
              this.resultsForm.get(`right.unitOptions.extras`).patchValue(this.rightExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`right.unitOptions.extras`).patchValue(this.extras_FilterLists[1][0], { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`right.unitOptions.extras`).patchValue(this.extras_FilterLists[1][0], { emitEvent: false });
          }

          //////////////////////
          // UPS Rule with EL //
          //////////////////////
          if (this.rightHighLimit.internalValue == 'N') {
            this.ups_FilterLists[1] = ['Y'];
            this.resultsForm.get(`right.unitOptions.ups`).patchValue(this.ups_FilterLists[1][0], { emitEvent: false });
          }
          else {
            this.ups_FilterLists[1] = [];
            if (!isUndefined(this.rightUps.enumeration)) {
              if (!isUndefined(this.rightUps.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`right.unitOptions.ups`).patchValue(this.rightUps.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.rightUps.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`right.unitOptions.ups`).patchValue(this.rightUps.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`right.unitOptions.ups`).patchValue('N', { emitEvent: false });
              }

            }
            else {
              this.resultsForm.get(`right.unitOptions.ups`).patchValue('N', { emitEvent: false });
            }
          }

        }
        else if (this.rightActuator.internalValue == 'PN') {
          /////////////////////////
          // Actuation set to PN //
          /////////////////////////

          this.controlValveFeedback_FilterLists[1] = []

          if (!isUndefined(this.rightControlValveFeedback.enumeration)) {
            if (!isUndefined(this.rightControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`right.unitOptions.controlValveFeedback`).patchValue(this.rightControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.rightControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`right.unitOptions.controlValveFeedback`).patchValue(this.rightControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`right.unitOptions.controlValveFeedback`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`right.unitOptions.controlValveFeedback`).patchValue('N', { emitEvent: false });
          }

          this.extras_FilterLists[1] = [];
          if (!isUndefined(this.rightExtras.enumeration)) {

            if (!isUndefined(this.rightExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`right.unitOptions.extras`).patchValue(this.rightExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.rightExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`right.unitOptions.extras`).patchValue(this.rightExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`right.unitOptions.extras`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`right.unitOptions.extras`).patchValue('N', { emitEvent: false });
          }

          //////////////////////
          // UPS Rule with PN //
          //////////////////////
          if (this.ups_FilterLists[1] !== []) {
            this.ups_FilterLists[1] = [];
            if (!isUndefined(this.rightUps.enumeration)) {

              if (!isUndefined(this.rightUps.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`right.unitOptions.ups`).patchValue(this.rightUps.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.rightUps.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`right.unitOptions.ups`).patchValue(this.rightUps.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`right.unitOptions.ups`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`right.unitOptions.ups`).patchValue('N', { emitEvent: false });
            }
          }
        }
      }
    }

    /************************************************************************
     *EHHCC*
     *******/
    if (!isNullOrUndefined(rt.easiHeatUnit) && (side == 'right' || side == 'both')) {
      if (rt.easiHeatUnit.substr(0, 5) == 'EHHCC') {
        let size = rt.easiHeatUnit.substr(5, 6);

        //Gasket Material Rule
        //Heat Seal F (G2,G5) is only available for the TS6 from alfa laval
        //FYI: G5 is achieved by selecting G2 and then selecting "Allow High Design Pressure" check box in the UI
        if (!rt.heatExchangePlateType.includes('TS6')) {
          this.gasketMaterial_FilterLists[1] = ['G1']; //there is no need to include G3 in here as it's not available on any HTG units and this rule has been implemented at the DB level. //G3 G6 RULE --> WRAS is not required for HTG systems so G3 and G6 are not included in any HTG systems
        }
        else {
          this.gasketMaterial_FilterLists[1] = ['G1','G2']
        }

        if (size == '2' && this.htgCcInletPipeVeloctiyOver40ms) {

          this.manualIsolation_FilterLists[1] = ['N', 'V2'];
          if (!isUndefined(this.rightManualIsolation.enumeration)) {
            if (this.manualIsolation_FilterLists[1].includes(this.rightManualIsolation.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value)) {
              this.resultsForm.get(`right.unitOptions.manualIsolation`).patchValue(this.rightManualIsolation.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`right.unitOptions.manualIsolation`).patchValue(this.manualIsolation_FilterLists[1][0], { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`right.unitOptions.manualIsolation`).patchValue(this.manualIsolation_FilterLists[1][0], { emitEvent: false });
          }

        }
        if (size == '3') {
          if (pc.designPressure12Bar) {
            this.actuator_FilterLists[1] = ['PN'];
            this.highLimit_FilterLists[1]= ['N','SIHL']
          }
          if (this.htgCcInletPipeVeloctiyOver40ms) {
            this.manualIsolation_FilterLists[1] = ['N', 'V1', 'V2'];
            if (!isUndefined(this.rightManualIsolation.enumeration)) {
              if (this.manualIsolation_FilterLists[1].includes(this.rightManualIsolation.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value)) {
                this.resultsForm.get(`right.unitOptions.manualIsolation`).patchValue(this.rightManualIsolation.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`right.unitOptions.manualIsolation`).patchValue(this.manualIsolation_FilterLists[1][0], { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`right.unitOptions.manualIsolation`).patchValue(this.manualIsolation_FilterLists[1][0], { emitEvent: false });
            }
          }
        }

        //////////////////////////////////
        // Actuation Rules under HTG CC //
        //////////////////////////////////
        //  Actuation set to EL //
        //////////////////////////
        if (this.rightActuator.internalValue == 'EL') {

          this.controlValveFeedback_FilterLists[1] = ['CVF']
          this.resultsForm.get(`right.unitOptions.controlValveFeedback`).patchValue(this.controlValveFeedback_FilterLists[1][0], { emitEvent: false });

          this.extras_FilterLists[1] = ['N', 'GP'];
          if (!isUndefined(this.rightExtras.enumeration)) {
            if (this.extras_FilterLists[1].includes(this.rightExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value)) {
              this.resultsForm.get(`right.unitOptions.extras`).patchValue(this.rightExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`right.unitOptions.extras`).patchValue(this.extras_FilterLists[1][0], { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`right.unitOptions.extras`).patchValue(this.extras_FilterLists[1][0], { emitEvent: false });
          }

        }
        else if (this.rightActuator.internalValue == 'PN') {
          /////////////////////////
          // Actuation set to PN //
          /////////////////////////

          this.controlValveFeedback_FilterLists[1] = []
          if (!isUndefined(this.rightControlValveFeedback.enumeration)) {

            if (!isUndefined(this.rightControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`right.unitOptions.controlValveFeedback`).patchValue(this.rightControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.rightControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`right.unitOptions.controlValveFeedback`).patchValue(this.rightControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`right.unitOptions.controlValveFeedback`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`right.unitOptions.controlValveFeedback`).patchValue('N', { emitEvent: false });
          }

          this.extras_FilterLists[1] = [];
          if (!isUndefined(this.rightExtras.enumeration)) {

            if (!isUndefined(this.rightExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`right.unitOptions.extras`).patchValue(this.rightExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.rightExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`right.unitOptions.extras`).patchValue(this.rightExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`right.unitOptions.extras`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`right.unitOptions.extras`).patchValue('N', { emitEvent: false });
          }
        }
      }
    }

    /************************************************************************
     *EHHSC*
     *******/
    if (!isNullOrUndefined(lt.easiHeatUnit) && (side == 'left' || side == 'both')) {
      if (lt.easiHeatUnit.substr(0, 5) == 'EHHSC') {
        let cvSize = lt.easiHeatUnit.substr(5, 6);

        //exclude 'GSX Process Panel' if user is not in the UK
        if (pc.globalVariance != 'UK') {
          this.controlSystem_FilterLists[0] = ['P1']; //ABB -> P1 ONLY, exclude 'GSX Process Panel' -> B1
        }
        else {
          this.controlSystem_FilterLists[0] = [];
        }

        //Gasket Material Rule
        //Heat Seal F (G2,G5) is only available for the TS6 from alfa laval
        //FYI: G5 is achieved by selecting G2 and then selecting "Allow High Design Pressure" check box in the UI
        if (!lt.heatExchangePlateType.includes('TS6')) {
          this.gasketMaterial_FilterLists[0] = ['G1']; //there is no need to include G3 in here as it's not available on any HTG units and this rule has been implemented at the DB level. //G3 G6 RULE --> WRAS is not required for HTG systems so G3 and G6 are not included in any HTG systems
        } else {
          this.gasketMaterial_FilterLists[0] = ['G1','G2'];
        }

        //Actuation Rules
        if (cvSize == '5') {
          if (this.convertedToBargPressure > 8.8 || (pc.designPressure12Bar && this.leftHighLimit.internalValue == 'N')) {
            this.actuator_FilterLists[0] = ['PN'];
          }
          else if (this.convertedToBargPressure <= 8.8 && (pc.designPressure12Bar && this.leftHighLimit.internalValue != 'N')) {
            this.actuator_FilterLists[0] = [];
          }
        }
        if (cvSize == '6') {
          if (this.convertedToBargPressure > 5.3 || pc.designPressure12Bar) {
            this.actuator_FilterLists[0] = ['PN'];
          }
        }

        //Manual Isolation Rules
        if (lt.heatExchangePlateType == 'T10M' && lt.steamCvSplitRangeKv != '0') {// if  !='0'  is true, it means that the unit is Split Range 
          this.manualIsolation_FilterLists[0] = ['N', 'V1', 'V2'];
        }
        if (cvSize == '4' && lt.steamCvSplitRangeKv != '0') {// if  !='0'  is true, it means that the unit is Split Range 
          this.manualIsolation_FilterLists[0] = ['N', 'V2'];
        }
        if (cvSize == '5' && lt.steamCvSplitRangeKv == '0') {// if  =='0'  is true, it means that the unit is NOT a Split Range 
          this.manualIsolation_FilterLists[0] = ['N', 'V2'];
        }
        if (cvSize == '5' && lt.steamCvSplitRangeKv != '0') {// if  !='0'  is true, it means that the unit is Split Range 
          this.manualIsolation_FilterLists[0] = ['N', 'V1', 'V2'];
        }
        if (cvSize == '6') {
          this.manualIsolation_FilterLists[0] = ['N', 'V1', 'V2'];
        }



        //High Limit Rules
        if (this.leftControlSystem.internalValue == 'B1') { //this rule came from new Control System Option 'GSX Process Panel'

          this.highLimit_FilterLists[0] = ['SIHL', 'IHL'];
          if (!isUndefined(this.leftHighLimit.enumeration)) {
            if (this.highLimit_FilterLists[0].includes(this.leftHighLimit.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value)) {
              this.resultsForm.get(`left.unitOptions.highLimit`).patchValue(this.leftHighLimit.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.highLimit`).patchValue(this.highLimit_FilterLists[0][0], { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.highLimit`).patchValue(this.highLimit_FilterLists[0][0], { emitEvent: false });
          }

        }
        else {
          this.highLimit_FilterLists[0] = [];
          if (!isUndefined(this.leftHighLimit.enumeration)) {

            if (!isUndefined(this.leftHighLimit.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`left.unitOptions.highLimit`).patchValue(this.leftHighLimit.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.leftHighLimit.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`left.unitOptions.highLimit`).patchValue(this.leftHighLimit.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.highLimit`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.highLimit`).patchValue('N', { emitEvent: false });
          }

        }

        if (cvSize == '5') {
          if (this.convertedToBargPressure > 6.9) {//rule 6
            if (lt.steamCvSplitRangeKv != '0') {// if  !='0'  is true, it means that the unit is Split Range ) {
              if (this.leftActuator.internalValue == 'EL' && this.leftControlSystem.internalValue != 'B1') {
                this.highLimit_FilterLists[0] = ['N'];
                this.leftHighLimit.internalValue = 'N'
              }
              else if (this.leftControlSystem.internalValue != 'B1') { //rule 9 + Control System Rule
                this.highLimit_FilterLists[0] = ['N', 'SIHL'];
              }
            }
          }
        }
        if (cvSize == '6') {
          if (lt.steamCvSplitRangeKv != '0') {// rule 7  // if  !='0'  is true, it means that the unit is Split Range )
            if (this.leftActuator.internalValue == 'EL' && this.leftControlSystem.internalValue != 'B1') {
              this.highLimit_FilterLists[0] = ['N'];
              this.leftHighLimit.internalValue = 'N'
            }
            else if (this.leftControlSystem.internalValue != 'B1') { //rule 10 + Control System Rule
              this.highLimit_FilterLists[0] = ['N', 'SHIL']
            }
          }
          else {
            if (this.convertedToBargPressure > 6.9) {//rule 5
              if (this.leftActuator.internalValue == 'EL' && this.leftControlSystem.internalValue != 'B1') {
                this.highLimit_FilterLists[0] = ['N'];
                this.leftHighLimit.internalValue = 'N'
              }
              else if (this.leftControlSystem.internalValue != 'B1') { //rule 8 + Control System Rule
                this.highLimit_FilterLists[0] = ['N', 'SIHL', 'INAIL']
              }
            }
          }

          if (pc.designPressure12Bar) {
            this.highLimit_FilterLists[0] = ['SIHL']
          }
        }


        /////////////////////////////////////
        // Actuation Settings under HTG SC //
        /////////////////////////////////////
        //  Actuation set to EL //
        //////////////////////////
        if (this.leftActuator.internalValue == 'EL') {

          this.controlValveFeedback_FilterLists[0] = ['CVF']
          this.resultsForm.get(`left.unitOptions.controlValveFeedback`).patchValue(this.controlValveFeedback_FilterLists[0][0], { emitEvent: false });

          this.extras_FilterLists[0] = ['N', 'GP'];
          if (!isUndefined(this.leftExtras.enumeration)) {
            if (this.extras_FilterLists[0].includes(this.leftExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value)) {
              this.resultsForm.get(`left.unitOptions.extras`).patchValue(this.leftExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.extras`).patchValue(this.extras_FilterLists[0][0], { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.extras`).patchValue(this.extras_FilterLists[0][0], { emitEvent: false });
          }
          
          //////////////////////
          // UPS Rule with EL //
          //////////////////////
          if (this.leftControlSystem.internalValue == 'B1') { //this rule came from new Control System Option 'GSX Process Panel'
            this.ups_FilterLists[0] = ['N'];
            this.resultsForm.get(`left.unitOptions.ups`).patchValue(this.ups_FilterLists[0][0], { emitEvent: false });
          }
          else if (this.leftHighLimit.internalValue == 'N') {
            this.ups_FilterLists[0] = ['Y'];
            this.resultsForm.get(`left.unitOptions.ups`).patchValue(this.ups_FilterLists[0][0], { emitEvent: false });
          }
          else {
            this.ups_FilterLists[0] = [];
            if (!isUndefined(this.leftUps.enumeration)) {

              if (!isUndefined(this.leftUps.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`left.unitOptions.ups`).patchValue(this.leftUps.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.leftUps.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`left.unitOptions.ups`).patchValue(this.leftUps.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`left.unitOptions.ups`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`left.unitOptions.ups`).patchValue('N', { emitEvent: false });
            }
          }

        }
        else if (this.leftActuator.internalValue == 'PN') {
          /////////////////////////
          // Actuation set to PN //
          /////////////////////////
          if (this.leftControlSystem.internalValue == 'B1') {

            this.controlValveFeedback_FilterLists[0] = ['N']
            this.resultsForm.get(`left.unitOptions.controlValveFeedback`).patchValue(this.controlValveFeedback_FilterLists[0][0], { emitEvent: false });

            this.extras_FilterLists[0] = ['N', 'GP'];
            if (!isUndefined(this.leftExtras.enumeration)) {
              if (this.extras_FilterLists[0].includes(this.leftExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value)) {
                this.resultsForm.get(`left.unitOptions.extras`).patchValue(this.leftExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`left.unitOptions.extras`).patchValue(this.extras_FilterLists[0][0], { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`left.unitOptions.extras`).patchValue(this.extras_FilterLists[0][0], { emitEvent: false });
            }

          } else {

            this.controlValveFeedback_FilterLists[0] = []
            if (!isUndefined(this.leftControlValveFeedback.enumeration)) {

              if (!isUndefined(this.leftControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`left.unitOptions.controlValveFeedback`).patchValue(this.leftControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.leftControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`left.unitOptions.controlValveFeedback`).patchValue(this.leftControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`left.unitOptions.controlValveFeedback`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`left.unitOptions.controlValveFeedback`).patchValue('N', { emitEvent: false });
            }
          

            this.extras_FilterLists[0] = [];
            if (!isUndefined(this.leftExtras.enumeration)) {
            
              if (!isUndefined(this.leftExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`left.unitOptions.extras`).patchValue(this.leftExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.leftExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`left.unitOptions.extras`).patchValue(this.leftExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`left.unitOptions.extras`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`left.unitOptions.extras`).patchValue('N', { emitEvent: false });
            }
          }
         
          //////////////////////
          // UPS Rule with PN //
          //////////////////////
          if (this.leftControlSystem.internalValue == 'B1') { //this rule came from new Control System Option 'GSX Process Panel'
            this.ups_FilterLists[0] = ['N'];
            this.resultsForm.get(`left.unitOptions.ups`).patchValue(this.ups_FilterLists[0][0], { emitEvent: false });
          }
          else if (this.ups_FilterLists[0].length > 0) {
            this.ups_FilterLists[0] = [];
            if (!isUndefined(this.leftUps.enumeration)) {

              if (!isUndefined(this.leftUps.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`left.unitOptions.ups`).patchValue(this.leftUps.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.leftUps.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`left.unitOptions.ups`).patchValue(this.leftUps.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`left.unitOptions.ups`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`left.unitOptions.ups`).patchValue('N', { emitEvent: false });
            }
          }
        }

        //////////////////////////
        // Control System Rules //
        //////////////////////////
        if (this.leftControlSystem.internalValue == 'B1') {

          this.integrityTest_FilterLists[0] = ['N'];
          this.resultsForm.get(`left.unitOptions.integrityTest`).patchValue('N', { emitEvent: false });

          this.dataCollection_FilterLists[0] = ['N'];
          this.resultsForm.get(`left.unitOptions.dataCollection`).patchValue('N', { emitEvent: false });

          this.ta11_FilterLists[0] = ['N'];
          this.resultsForm.get(`left.unitOptions.ta11`).patchValue('N', { emitEvent: false });

          this.ta31_FilterLists[0] = ['N'];
          this.resultsForm.get(`left.unitOptions.ta31`).patchValue('N', { emitEvent: false });

          this.ta41_FilterLists[0] = ['N'];
          this.resultsForm.get(`left.unitOptions.ta41`).patchValue('N', { emitEvent: false });

          this.ta01_FilterLists[0] = ['N'];
          this.resultsForm.get(`left.unitOptions.ta01`).patchValue('N', { emitEvent: false });

          this.pa31_FilterLists[0] = ['N'];
          this.resultsForm.get(`left.unitOptions.pa31`).patchValue('N', { emitEvent: false });

          this.fa11_FilterLists[0] = ['N'];
          this.resultsForm.get(`left.unitOptions.fa11`).patchValue('N', { emitEvent: false });

          this.communications_FilterLists[0] = ['C0'];
          this.resultsForm.get(`left.unitOptions.communications`).patchValue('C0', { emitEvent: false });

        } else {

          this.integrityTest_FilterLists[0] = [];
          if (!isUndefined(this.leftIntegrityTest.enumeration)) {

            if (!isUndefined(this.leftIntegrityTest.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`left.unitOptions.integrityTest`).patchValue(this.leftIntegrityTest.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.leftIntegrityTest.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`left.unitOptions.integrityTest`).patchValue(this.leftIntegrityTest.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.integrityTest`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.integrityTest`).patchValue('N', { emitEvent: false });
          }

          this.dataCollection_FilterLists[0] = [];
          if (!isUndefined(this.leftDataCollection.enumeration)) {

            if (!isUndefined(this.leftDataCollection.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`left.unitOptions.dataCollection`).patchValue(this.leftDataCollection.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.leftDataCollection.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`left.unitOptions.dataCollection`).patchValue(this.leftDataCollection.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.dataCollection`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.dataCollection`).patchValue('N', { emitEvent: false });
          }

          this.ta11_FilterLists[0] = [];
          if (!isUndefined(this.leftTa11.enumeration)) {

            if (!isUndefined(this.leftTa11.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`left.unitOptions.ta11`).patchValue(this.leftTa11.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.leftTa11.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`left.unitOptions.ta11`).patchValue(this.leftTa11.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.ta11`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.ta11`).patchValue('N', { emitEvent: false });
          }

          this.ta31_FilterLists[0] = [];
          if (!isUndefined(this.leftTa31.enumeration)) {

            if (!isUndefined(this.leftTa31.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`left.unitOptions.ta31`).patchValue(this.leftTa31.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.leftTa31.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`left.unitOptions.ta31`).patchValue(this.leftTa31.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.ta31`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.ta31`).patchValue('N', { emitEvent: false });
          }

          this.ta41_FilterLists[0] = [];
          if (!isUndefined(this.leftTa41.enumeration)) {

            if (!isUndefined(this.leftTa41.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`left.unitOptions.ta41`).patchValue(this.leftTa41.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.leftTa41.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`left.unitOptions.ta41`).patchValue(this.leftTa41.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.ta41`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.ta41`).patchValue('N', { emitEvent: false });
          }

          this.ta01_FilterLists[0] = [];
          if (!isUndefined(this.leftTa01.enumeration)) {

            if (!isUndefined(this.leftTa01.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`left.unitOptions.ta01`).patchValue(this.leftTa01.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.leftTa01.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`left.unitOptions.ta01`).patchValue(this.leftTa01.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.ta01`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.ta01`).patchValue('N', { emitEvent: false });
          }

          this.pa31_FilterLists[0] = [];
          if (!isUndefined(this.leftPa31.enumeration)) {

            if (!isUndefined(this.leftPa31.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`left.unitOptions.pa31`).patchValue(this.leftPa31.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.leftTa11.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`left.unitOptions.pa31`).patchValue(this.leftPa31.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.pa31`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.pa31`).patchValue('N', { emitEvent: false });
          }

          this.fa11_FilterLists[0] = [];
          if (!isUndefined(this.leftFa11.enumeration)) {

            if (!isUndefined(this.leftFa11.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`left.unitOptions.fa11`).patchValue(this.leftFa11.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.leftFa11.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`left.unitOptions.fa11`).patchValue(this.leftFa11.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.fa11`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.fa11`).patchValue('N', { emitEvent: false });
          }

          this.communications_FilterLists[0] = [];
          if (!isUndefined(this.leftCommunications.enumeration)) {

            if (!isUndefined(this.leftCommunications.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`left.unitOptions.communications`).patchValue(this.leftCommunications.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.leftCommunications.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`left.unitOptions.communications`).patchValue(this.leftCommunications.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.communications`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.communications`).patchValue('N', { emitEvent: false });
          }

        }

      }
    }

    //DOMESTIC HOT WATER (DHW)////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////
    // EHDDC //
    ///////////
    if (!isNullOrUndefined(rt.easiHeatUnit) && (side == 'right' || side == 'both')) {
      if (rt.easiHeatUnit.substr(0, 5) == 'EHDDC') {
        let cvSize = rt.easiHeatUnit.substr(5, 6);

        //Gasket Material Rule
        //Heat Seal F (G2,G5) is only available for the TS6 from alfa laval
        //FYI: G5 is achieved by selecting G2 and then selecting "Allow High Design Pressure" check box in the UI
        if (rt.heatExchangePlateType.includes('TS6')) {

          //this additional 'epdmwGasketPressureCheck' check has been added at the later date as a result of the software testing RPU 18/06/2024
          if (!rt.epdmwGasketPressureCheck) {
            this.gasketMaterial_FilterLists[1] = ['G1', 'G2']
          } else {
            this.gasketMaterial_FilterLists[1] = ['G1', 'G2', 'G3']; 
          }
        }

        //Gasket Material Rule
        //T8 does not have access to EPDMW G3 and G6
        //FYI: G6 is achieved by selecting G3 and then selecting "Allow High Design Pressure" check box in the UI
        if (rt.heatExchangePlateType.includes('T8')) {
          this.gasketMaterial_FilterLists[1] = ['G1'];
        }

        if (rt.heatExchangePlateType.includes('T10')) {

          //this additional 'epdmwGasketPressureCheck' check has been added at the later date as a result of the software testing RPU 18/06/2024
          if (!rt.epdmwGasketPressureCheck) {
            this.gasketMaterial_FilterLists[1] = ['G1']
          } else {
            this.gasketMaterial_FilterLists[1] = ['G1', 'G3'];
          }
        }

        //Actuation Rule 3  + designPressure12Bar rule
        if (cvSize == '5') {
          if (this.convertedToBargPressure > 8.8 || (pc.designPressure12Bar && this.rightHighLimit.internalValue == 'N')) {
            this.actuator_FilterLists[1] = ['PN'];
          }
          else if (this.convertedToBargPressure <= 8.8 && (pc.designPressure12Bar && this.rightHighLimit.internalValue != 'N')) {
            this.actuator_FilterLists[1] = [];
          }
        }
        //Actuation Rule 4 + designPressure12Bar rule
        if (cvSize == '6') {
          if (this.convertedToBargPressure > 5.3 || pc.designPressure12Bar) {
            this.actuator_FilterLists[1] = ['PN'];

            //High Limit Rule 4 - it must sits with in Actuation Rule 4 + designPressure12Bar rule
            if (pc.designPressure12Bar) {
              this.highLimit_FilterLists[1] = ['N', 'SIHL']
            }
            else if (this.convertedToBargPressure > 6.9) {
              this.highLimit_FilterLists[1] = ['N', 'SIHL']
            }
          }
        }

        //Manual Isolation Rules
        if (cvSize == '5') {
          this.manualIsolation_FilterLists[1] = ['N', 'V2'];
        }
        if (cvSize == '6') {
          this.manualIsolation_FilterLists[1] = ['N', 'V1', 'V2'];
        }

        //High Limit Rule 3
        if (cvSize == '6') {
          if (this.convertedToBargPressure > 6.9) {
            if (this.rightActuator.internalValue == 'EL') {
              this.highLimit_FilterLists[1] = ['N']
              this.rightHighLimit.internalValue = 'N'
            }
          }
        }

        //////////////////////////////////
        // Actuation Rules under DHW DC //
        //////////////////////////////////
        //  Actuation set to EL //
        //////////////////////////
        if (this.rightActuator.internalValue == 'EL') {
          this.integrityTest_FilterLists[1] = ['N'];
          this.resultsForm.get(`right.unitOptions.integrityTest`).patchValue(this.integrityTest_FilterLists[1][0], { emitEvent: false });

          this.controlValveFeedback_FilterLists[1] = ['CVF']
          this.resultsForm.get(`right.unitOptions.controlValveFeedback`).patchValue(this.controlValveFeedback_FilterLists[1][0], { emitEvent: false });

          this.pa31_FilterLists[1] = [];
          if (!isUndefined(this.rightPa31.enumeration)) {
            if (!isUndefined(this.rightPa31.enumeration.enumerationDefinitions.find(opt => opt.sequence == 1))) {
              this.resultsForm.get(`right.unitOptions.pa31`).patchValue(this.rightPa31.enumeration.enumerationDefinitions.find(opt => opt.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.rightPa31.enumeration.enumerationDefinitions.find(opt => opt.sequence == 2))) {
              this.resultsForm.get(`right.unitOptions.pa31`).patchValue(this.rightPa31.enumeration.enumerationDefinitions.find(opt => opt.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`right.unitOptions.pa31`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`right.unitOptions.pa31`).patchValue('N', { emitEvent: false });
          }

          this.ta31_FilterLists[1] = [];
          if (!isUndefined(this.rightTa31.enumeration)) {

            if (!isUndefined(this.rightTa31.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`right.unitOptions.ta31`).patchValue(this.rightTa31.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.rightTa31.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`right.unitOptions.ta31`).patchValue(this.rightTa31.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`right.unitOptions.ta31`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`right.unitOptions.ta31`).patchValue('N', { emitEvent: false });
          }

          this.extras_FilterLists[1] = ['N', 'GP']; //this comes from EL Actuation Rules 
          if (!isUndefined(this.rightExtras.enumeration)) {
            if (this.extras_FilterLists[1].includes(this.rightExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value)) {
              this.resultsForm.get(`right.unitOptions.extras`).patchValue(this.rightExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`right.unitOptions.extras`).patchValue(this.extras_FilterLists[1][0], { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`right.unitOptions.extras`).patchValue(this.extras_FilterLists[1][0], { emitEvent: false });
          }

          //////////////////////
          // UPS Rule with EL //
          //////////////////////
          if (this.rightHighLimit.internalValue == 'N') {
            this.ups_FilterLists[1] = ['Y'];
            this.resultsForm.get(`right.unitOptions.ups`).patchValue(this.ups_FilterLists[1][0], { emitEvent: false });
          }
          else {
            this.ups_FilterLists[1] = [];
            if (!isUndefined(this.rightUps.enumeration)) {

              if (!isUndefined(this.rightUps.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`right.unitOptions.ups`).patchValue(this.rightUps.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.rightUps.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`right.unitOptions.ups`).patchValue(this.rightUps.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`right.unitOptions.ups`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`right.unitOptions.ups`).patchValue('N', { emitEvent: false });
            }
          }
        }
        else if (this.rightActuator.internalValue == 'PN') {
          /////////////////////////
          // Actuation set to PN //
          /////////////////////////

          this.integrityTest_FilterLists[1] = [];
          if (!isUndefined(this.rightIntegrityTest.enumeration)) {

            if (!isUndefined(this.rightIntegrityTest.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`right.unitOptions.integrityTest`).patchValue(this.rightIntegrityTest.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.rightIntegrityTest.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`right.unitOptions.integrityTest`).patchValue(this.rightIntegrityTest.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`right.unitOptions.integrityTest`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`right.unitOptions.integrityTest`).patchValue('N', { emitEvent: false });
          }

          ////////////////////////////////////////////////////////////////
          //Integrity Test Rules under DHW SC with PN actuator selected //
          ////////////////////////////////////////////////////////////////
          //Integrity Test set to YES //
          //////////////////////////////

          if (this.rightIntegrityTest.internalValue == 'T') {

            this.pa31_FilterLists[1] = ['Y'];
            this.ta31_FilterLists[1] = ['Y'];
            this.resultsForm.get(`right.unitOptions.pa31`).patchValue(this.pa31_FilterLists[1][0], { emitEvent: false });
            this.resultsForm.get(`right.unitOptions.ta31`).patchValue(this.ta31_FilterLists[1][0], { emitEvent: false });

            this.controlValveFeedback_FilterLists[1] = []
            if (!isUndefined(this.rightControlValveFeedback.enumeration)) {

              if (!isUndefined(this.rightControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`right.unitOptions.controlValveFeedback`).patchValue(this.rightControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.rightControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`right.unitOptions.controlValveFeedback`).patchValue(this.rightControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`right.unitOptions.controlValveFeedback`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`right.unitOptions.controlValveFeedback`).patchValue('N', { emitEvent: false });
            }

            this.extras_FilterLists[1] = ['AP', 'GPAP'];
            if (!isUndefined(this.rightExtras.enumeration)) {
              if (this.extras_FilterLists[1].includes(this.rightExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value)) {
                this.resultsForm.get(`right.unitOptions.extras`).patchValue(this.rightExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`right.unitOptions.extras`).patchValue(this.extras_FilterLists[1][0], { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`right.unitOptions.extras`).patchValue(this.extras_FilterLists[1][0], { emitEvent: false });
            }
          }
          else if (this.rightIntegrityTest.internalValue == 'N') {

            //////////////////////////////
            // Integrity Test set to NO //
            //////////////////////////////
            this.pa31_FilterLists[1] = [];
            if (!isUndefined(this.rightPa31.enumeration)) {
              if (!isUndefined(this.rightPa31.enumeration.enumerationDefinitions.find(opt => opt.sequence == 1))) {
                this.resultsForm.get(`right.unitOptions.pa31`).patchValue(this.rightPa31.enumeration.enumerationDefinitions.find(opt => opt.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.rightPa31.enumeration.enumerationDefinitions.find(opt => opt.sequence == 2))) {
                this.resultsForm.get(`right.unitOptions.pa31`).patchValue(this.rightPa31.enumeration.enumerationDefinitions.find(opt => opt.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`right.unitOptions.pa31`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`right.unitOptions.pa31`).patchValue('N', { emitEvent: false });
            }


            this.ta31_FilterLists[1] = [];
            if (!isUndefined(this.rightTa31.enumeration)) {

              if (!isUndefined(this.rightTa31.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`right.unitOptions.ta31`).patchValue(this.rightTa31.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.rightTa31.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`right.unitOptions.ta31`).patchValue(this.rightTa31.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`right.unitOptions.ta31`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`right.unitOptions.ta31`).patchValue('N', { emitEvent: false });
            }

            this.controlValveFeedback_FilterLists[1] = []
            if (!isUndefined(this.rightControlValveFeedback.enumeration)) {

              if (!isUndefined(this.rightControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`right.unitOptions.controlValveFeedback`).patchValue(this.rightControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.rightControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`right.unitOptions.controlValveFeedback`).patchValue(this.rightControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`right.unitOptions.controlValveFeedback`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`right.unitOptions.controlValveFeedback`).patchValue('N', { emitEvent: false });
            }

            this.extras_FilterLists[1] = [];
            if (!isUndefined(this.rightExtras.enumeration)) {

              if (!isUndefined(this.rightExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`right.unitOptions.extras`).patchValue(this.rightExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.rightExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`right.unitOptions.extras`).patchValue(this.rightExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`right.unitOptions.extras`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`right.unitOptions.extras`).patchValue('N', { emitEvent: false });
            }
          }

          //////////////////////
          // UPS Rule with PN //
          //////////////////////
          if (this.ups_FilterLists[1] !== []) {
            this.ups_FilterLists[1] = [];
            if (!isUndefined(this.rightUps.enumeration)) {

              if (!isUndefined(this.rightUps.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`right.unitOptions.ups`).patchValue(this.rightUps.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.rightUps.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`right.unitOptions.ups`).patchValue(this.rightUps.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`right.unitOptions.ups`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`right.unitOptions.ups`).patchValue('N', { emitEvent: false });
            }
          }
        }
      }
    }

    ////////////////////////////////////////////////////////////////////////
    // EHDSC //
    ///////////
    if (!isNullOrUndefined(lt.easiHeatUnit) && (side == 'left' || side == 'both')) {
      if (lt.easiHeatUnit.substr(0, 5) == 'EHDSC') {
        let cvSize = lt.easiHeatUnit.substr(5, 6);

        //exclude 'GSX Process Panel' if user is not in the UK
        if (pc.globalVariance != 'UK') {
          this.controlSystem_FilterLists[0] = ['P1']; //ABB -> P1 ONLY, exclude 'GSX Process Panel' -> B1
        }
        else {
          this.controlSystem_FilterLists[0] = [];
        }

        //Gasket Material Rule
        //Heat Seal F (G2,G5) is only available for the TS6 from alfa laval
        //FYI: G5 is achieved by selecting G2 and then selecting "Allow High Design Pressure" check box in the UI
        if (lt.heatExchangePlateType.includes('TS6')) {

          //this additional 'epdmwGasketPressureCheck' check has been added at the later date as a result of the software testing RPU 18/06/2024
          if (!lt.epdmwGasketPressureCheck) {
            this.gasketMaterial_FilterLists[0] = ['G1', 'G2']
          } else {
            this.gasketMaterial_FilterLists[0] = ['G1', 'G2', 'G3'];
          }
        }



        //Gasket Material Rule
        //T8 does not have access to EPDMW G3 and G6
        //FYI: G6 is achieved by selecting G3 and then selecting "Allow High Design Pressure" check box in the UI
        if (lt.heatExchangePlateType.includes('T8')) {
          this.gasketMaterial_FilterLists[0] = ['G1'];
        }

        if (lt.heatExchangePlateType.includes('T10')) {

          //this additional 'epdmwGasketPressureCheck' check has been added at the later date as a result of the software testing RPU 18/06/2024
          if (!lt.epdmwGasketPressureCheck) {
            this.gasketMaterial_FilterLists[0] = ['G1']
          } else {
            this.gasketMaterial_FilterLists[0] = ['G1','G3'];
          }
        }

        //Actuation Rules
        if (cvSize == '5') {
          if (this.convertedToBargPressure > 8.8 || (pc.designPressure12Bar && this.leftHighLimit.internalValue == 'N')) {
            this.actuator_FilterLists[0] = ['PN'];
            this.resultsForm.get(`left.unitOptions.actuator`).patchValue(this.actuator_FilterLists[0][0], { emitEvent: false });
          }
          else if (this.convertedToBargPressure <= 8.8 && (pc.designPressure12Bar && this.leftHighLimit.internalValue != 'N')) {
            this.actuator_FilterLists[0] = [];

            if (!isUndefined(this.leftActuator.enumeration)) {

              if (!isUndefined(this.leftActuator.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`left.unitOptions.actuator`).patchValue(this.leftActuator.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.leftActuator.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`left.unitOptions.actuator`).patchValue(this.leftActuator.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`left.unitOptions.actuator`).patchValue('PN', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`left.unitOptions.actuator`).patchValue('PN', { emitEvent: false });
            }

          }
        }
        if (cvSize == '6') {
          if (this.convertedToBargPressure > 5.3 || pc.designPressure12Bar) {

            this.actuator_FilterLists[0] = ['PN'];
          }
        }

        //Manual Isolation Rules
        if (lt.heatExchangePlateType == 'T10M' && lt.steamCvSplitRangeKv != '0') {// if  !='0'  is true, it means that the unit is Split Range 
          this.manualIsolation_FilterLists[0] = ['N', 'V1', 'V2'];
        }
        if (cvSize == '4' && lt.steamCvSplitRangeKv != '0') {// if  !='0'  is true, it means that the unit is Split Range 
          this.manualIsolation_FilterLists[0] = ['N', 'V2'];
        }
        if (cvSize == '5') {
          if (lt.steamCvSplitRangeKv != '0') {// if  !='0'  is true, it means that the unit is Split Range )
            this.manualIsolation_FilterLists[0] = ['N', 'V1', 'V2'];
          }
          else { //cvSize 5 without Split Range
            this.manualIsolation_FilterLists[0] = ['N', 'V2'];
          }
        }
        if (cvSize == '6') {
          this.manualIsolation_FilterLists[0] = ['N', 'V1', 'V2'];
        }



        //High Limit Rules
        if (this.leftControlSystem.internalValue == 'B1') { //this rule came from new Control System Option 'GSX Process Panel'

          this.highLimit_FilterLists[0] = ['SIHL', 'IHL'];
          if (!isUndefined(this.leftHighLimit.enumeration)) {
            if (this.highLimit_FilterLists[0].includes(this.leftHighLimit.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value)) {
              this.resultsForm.get(`left.unitOptions.highLimit`).patchValue(this.leftHighLimit.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.highLimit`).patchValue(this.highLimit_FilterLists[0][0], { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.highLimit`).patchValue(this.highLimit_FilterLists[0][0], { emitEvent: false });
          }
        }
        else {
          this.highLimit_FilterLists[0] = [];
          if (!isUndefined(this.leftHighLimit.enumeration)) {

            if (!isUndefined(this.leftHighLimit.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`left.unitOptions.highLimit`).patchValue(this.leftHighLimit.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.leftHighLimit.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`left.unitOptions.highLimit`).patchValue(this.leftHighLimit.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.highLimit`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.highLimit`).patchValue('N', { emitEvent: false });
          }

        }

        if (cvSize == '5') {
          if (this.convertedToBargPressure > 6.9) {//rule 6
            if (lt.steamCvSplitRangeKv != '0') {// if  !='0'  is true, it means that the unit is Split Range )
              if (this.leftActuator.internalValue == 'EL' && this.leftControlSystem.internalValue != 'B1') {
                this.highLimit_FilterLists[0] = ['N'];
                this.leftHighLimit.internalValue = 'N'
              }
              else if (this.leftControlSystem.internalValue != 'B1') { //rule 9 + Control System Rule
                this.highLimit_FilterLists[0] = ['N', 'SIHL'];
              }
            }
          }
        }
        if (cvSize == '6') {
          if (lt.steamCvSplitRangeKv != '0') {// rule 7 // if  !='0'  is true, it means that the unit is Split Range )
            if (this.leftActuator.internalValue == 'EL' && this.leftControlSystem.internalValue != 'B1') {
              this.highLimit_FilterLists[0] = ['N'];
              this.leftHighLimit.internalValue = 'N'
            }
            else if (this.leftControlSystem.internalValue != 'B1') { //rule 10 + Control System Rule
              this.highLimit_FilterLists[0] = ['N', 'SHIL']
            }
          }
          else {
            if (this.convertedToBargPressure > 6.9) { //rule 5
              if (this.leftActuator.internalValue == 'EL' && this.leftControlSystem.internalValue != 'B1') {
                this.highLimit_FilterLists[0] = ['N'];
                this.leftHighLimit.internalValue = 'N'
              }
              else if (this.leftControlSystem.internalValue != 'B1') { //rule 8 + Control System Rule
                this.highLimit_FilterLists[0] = ['N', 'SIHL', 'INAIL']
              }
            }
          }

          if (pc.designPressure12Bar) {
            if (this.leftControlSystem.internalValue != 'B1') {
              this.highLimit_FilterLists[0] = ['N', 'SIHL']
            }
            else {
              this.highLimit_FilterLists[0] = ['SIHL']
            }
            
          }

        }

        /////////////////////////////////////
        // Actuation Settings under DHW SC //
        /////////////////////////////////////
        //  Actuation set to EL //
        //////////////////////////
        if (this.leftActuator.internalValue == 'EL') {

          this.integrityTest_FilterLists[0] = ['N'];
          this.resultsForm.get(`left.unitOptions.integrityTest`).patchValue(this.integrityTest_FilterLists[0][0], { emitEvent: false });

          this.controlValveFeedback_FilterLists[0] = ['CVF']
          this.resultsForm.get(`left.unitOptions.controlValveFeedback`).patchValue(this.controlValveFeedback_FilterLists[0][0], { emitEvent: false });

          if (this.leftControlSystem.internalValue == 'B1') { //this rule came from new Control System Option 'GSX Process Panel'

            this.pa31_FilterLists[0] = ['N'];
            this.resultsForm.get(`left.unitOptions.pa31`).patchValue(this.pa31_FilterLists[0][0], { emitEvent: false });

            this.ta31_FilterLists[0] = ['N'];
            this.resultsForm.get(`left.unitOptions.ta31`).patchValue(this.ta31_FilterLists[0][0], { emitEvent: false });

          } else {

            this.pa31_FilterLists[0] = [];
            if (!isUndefined(this.leftPa31.enumeration)) {

              if (!isUndefined(this.leftPa31.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`left.unitOptions.pa31`).patchValue(this.leftPa31.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.leftPa31.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`left.unitOptions.pa31`).patchValue(this.leftPa31.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`left.unitOptions.pa31`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`left.unitOptions.pa31`).patchValue('N', { emitEvent: false });
            }

            this.ta31_FilterLists[0] = [];
            if (!isUndefined(this.leftTa31.enumeration)) {

              if (!isUndefined(this.leftTa31.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`left.unitOptions.ta31`).patchValue(this.leftTa31.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.leftTa31.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`left.unitOptions.ta31`).patchValue(this.leftTa31.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`left.unitOptions.ta31`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`left.unitOptions.ta31`).patchValue('N', { emitEvent: false });
            }
          }

          this.extras_FilterLists[0] = ['N', 'GP']; //this comes from EL Actuation Rules 
          if (!isUndefined(this.leftExtras.enumeration)) {
            if (this.extras_FilterLists[0].includes(this.leftExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value)) {
              this.resultsForm.get(`left.unitOptions.extras`).patchValue(this.leftExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.extras`).patchValue(this.extras_FilterLists[0][0], { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.extras`).patchValue(this.extras_FilterLists[0][0], { emitEvent: false });
          }

          //////////////////////
          // UPS Rule with EL //
          //////////////////////
          if (this.leftControlSystem.internalValue == 'B1') { //this rule came from new Control System Option 'GSX Process Panel'
            this.ups_FilterLists[0] = ['N'];
            this.resultsForm.get(`left.unitOptions.ups`).patchValue(this.ups_FilterLists[0][0], { emitEvent: false });
          }
          else if (this.leftHighLimit.internalValue == 'N') {
            this.ups_FilterLists[0] = ['Y'];
            this.resultsForm.get(`left.unitOptions.ups`).patchValue(this.ups_FilterLists[0][0], { emitEvent: false });
          }
          else {
            this.ups_FilterLists[0] = [];
            if (!isUndefined(this.leftUps.enumeration)) {

              if (!isUndefined(this.leftUps.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`left.unitOptions.ups`).patchValue(this.leftUps.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.leftUps.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`left.unitOptions.ups`).patchValue(this.leftUps.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`left.unitOptions.ups`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`left.unitOptions.ups`).patchValue('N', { emitEvent: false });
            }
          }

        }
        else if (this.leftActuator.internalValue == 'PN') {
          /////////////////////////
          // Actuation set to PN //
          /////////////////////////
          if (this.leftControlSystem.internalValue == 'B1') { //this rule came from new Control System Option 'GSX Process Panel'

            this.leftIntegrityTest.internalValue == 'N'
            this.integrityTest_FilterLists[0] = ['N'];
            this.resultsForm.get(`left.unitOptions.integrityTest`).patchValue(this.integrityTest_FilterLists[0][0], { emitEvent: false });

            this.pa31_FilterLists[0] = ['N'];
            this.resultsForm.get(`left.unitOptions.pa31`).patchValue(this.pa31_FilterLists[0][0], { emitEvent: false });

            this.ta31_FilterLists[0] = ['N'];
            this.resultsForm.get(`left.unitOptions.ta31`).patchValue(this.ta31_FilterLists[0][0], { emitEvent: false });
           
            this.controlValveFeedback_FilterLists[0] = ['N'];
            this.resultsForm.get(`left.unitOptions.controlValveFeedback`).patchValue(this.controlValveFeedback_FilterLists[0][0], { emitEvent: false });
            
            this.extras_FilterLists[0] = ['N', 'GP'];
            if (!isUndefined(this.leftExtras.enumeration)) {
              if (this.extras_FilterLists[0].includes(this.leftExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value)) {
                this.resultsForm.get(`left.unitOptions.extras`).patchValue(this.leftExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`left.unitOptions.extras`).patchValue(this.extras_FilterLists[0][0], { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`left.unitOptions.extras`).patchValue(this.extras_FilterLists[0][0], { emitEvent: false });
            }


          } else {
            this.integrityTest_FilterLists[0] = [];
            if (!isUndefined(this.leftIntegrityTest.enumeration)) {

              if (!isUndefined(this.leftIntegrityTest.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`left.unitOptions.integrityTest`).patchValue(this.leftIntegrityTest.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.leftIntegrityTest.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`left.unitOptions.integrityTest`).patchValue(this.leftIntegrityTest.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`left.unitOptions.integrityTest`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`left.unitOptions.integrityTest`).patchValue('N', { emitEvent: false });
            }
          }
         

          ////////////////////////////////////////////////////////////////
          //Integrity Test Rules under DHW SC with PN actuator selected //
          ////////////////////////////////////////////////////////////////
          //Integrity Test set to YES //
          //////////////////////////////

          if (this.leftIntegrityTest.internalValue == 'T' && this.leftControlSystem.internalValue != 'B1') { //the second condition of this IF statement came from new Control System Option 'GSX Process Panel'

            this.pa31_FilterLists[0] = ['Y'];
            this.ta31_FilterLists[0] = ['Y'];
            this.resultsForm.get(`left.unitOptions.pa31`).patchValue(this.pa31_FilterLists[0][0], { emitEvent: false });
            this.resultsForm.get(`left.unitOptions.ta31`).patchValue(this.ta31_FilterLists[0][0], { emitEvent: false });

            this.controlValveFeedback_FilterLists[0] = []
            if (!isUndefined(this.leftControlValveFeedback.enumeration)) {

              if (!isUndefined(this.leftControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`left.unitOptions.controlValveFeedback`).patchValue(this.leftControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.leftControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`left.unitOptions.controlValveFeedback`).patchValue(this.leftControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`left.unitOptions.controlValveFeedback`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`left.unitOptions.controlValveFeedback`).patchValue('N', { emitEvent: false });
            }

            this.extras_FilterLists[0] = ['AP', 'GPAP'];
            if (!isUndefined(this.leftExtras.enumeration)) {
              if (this.extras_FilterLists[0].includes(this.leftExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value)) {
                this.resultsForm.get(`left.unitOptions.extras`).patchValue(this.leftExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`left.unitOptions.extras`).patchValue(this.extras_FilterLists[0][0], { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`left.unitOptions.extras`).patchValue(this.extras_FilterLists[0][0], { emitEvent: false });
            }

          }
          else if (this.leftIntegrityTest.internalValue == 'N' && this.leftControlSystem.internalValue != 'B1') { //the second condition of this IF statement came from new Control System Option 'GSX Process Panel'

            //////////////////////////////
            // Integrity Test set to NO //
            //////////////////////////////
            this.pa31_FilterLists[0] = [];
            if (!isUndefined(this.leftPa31.enumeration)) {

              if (!isUndefined(this.leftPa31.enumeration.enumerationDefinitions.find(opt => opt.sequence == 1))) {
                this.resultsForm.get(`left.unitOptions.pa31`).patchValue(this.leftPa31.enumeration.enumerationDefinitions.find(opt => opt.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.leftPa31.enumeration.enumerationDefinitions.find(opt => opt.sequence == 2))) {
                this.resultsForm.get(`left.unitOptions.pa31`).patchValue(this.leftPa31.enumeration.enumerationDefinitions.find(opt => opt.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`left.unitOptions.pa31`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`left.unitOptions.pa31`).patchValue('N', { emitEvent: false });
            }

            this.ta31_FilterLists[0] = [];
            if (!isUndefined(this.leftTa31.enumeration)) {

              if (!isUndefined(this.leftTa31.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`left.unitOptions.ta31`).patchValue(this.leftTa31.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.leftTa31.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`left.unitOptions.ta31`).patchValue(this.leftTa31.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`left.unitOptions.ta31`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`left.unitOptions.ta31`).patchValue('N', { emitEvent: false });
            }

            this.controlValveFeedback_FilterLists[0] = []
            if (!isUndefined(this.leftControlValveFeedback.enumeration)) {

              if (!isUndefined(this.leftControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`left.unitOptions.controlValveFeedback`).patchValue(this.leftControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.leftControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`left.unitOptions.controlValveFeedback`).patchValue(this.leftControlValveFeedback.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`left.unitOptions.controlValveFeedback`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`left.unitOptions.controlValveFeedback`).patchValue('N', { emitEvent: false });
            }

            this.extras_FilterLists[0] = [];
            if (!isUndefined(this.leftExtras.enumeration)) {

              if (!isUndefined(this.leftExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`left.unitOptions.extras`).patchValue(this.leftExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.leftExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`left.unitOptions.extras`).patchValue(this.leftExtras.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`left.unitOptions.extras`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`left.unitOptions.extras`).patchValue('N', { emitEvent: false });
            }
          }



          //////////////////////
          // UPS Rule with PN //
          //////////////////////
          if (this.leftControlSystem.internalValue == 'B1') { //this rule came from new Control System Option 'GSX Process Panel'
            this.ups_FilterLists[0] = ['N'];
            this.resultsForm.get(`left.unitOptions.ups`).patchValue(this.ups_FilterLists[0][0], { emitEvent: false });
          }
          else if (this.ups_FilterLists[0].length > 0) {
            this.ups_FilterLists[0] = [];
            if (!isUndefined(this.leftUps.enumeration)) {

              if (!isUndefined(this.leftUps.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
                this.resultsForm.get(`left.unitOptions.ups`).patchValue(this.leftUps.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
              }
              else if (!isUndefined(this.leftUps.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
                this.resultsForm.get(`left.unitOptions.ups`).patchValue(this.leftUps.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
              }
              else {
                this.resultsForm.get(`left.unitOptions.ups`).patchValue('N', { emitEvent: false });
              }
            }
            else {
              this.resultsForm.get(`left.unitOptions.ups`).patchValue('N', { emitEvent: false });
            }
          }
        }

        //////////////////////////
        // Control System Rules //
        //////////////////////////
        if (this.leftControlSystem.internalValue == 'B1') {

          this.dataCollection_FilterLists[0] = ['N'];
          this.resultsForm.get(`left.unitOptions.dataCollection`).patchValue('N', { emitEvent: false });

          this.ta11_FilterLists[0] = ['N'];
          this.resultsForm.get(`left.unitOptions.ta11`).patchValue('N', { emitEvent: false });

          this.ta41_FilterLists[0] = ['N'];
          this.resultsForm.get(`left.unitOptions.ta41`).patchValue('N', { emitEvent: false });

          this.ta01_FilterLists[0] = ['N'];
          this.resultsForm.get(`left.unitOptions.ta01`).patchValue('N', { emitEvent: false });

          this.fa11_FilterLists[0] = ['N'];
          this.resultsForm.get(`left.unitOptions.fa11`).patchValue('N', { emitEvent: false });

          this.communications_FilterLists[0] = ['C0'];
          this.resultsForm.get(`left.unitOptions.communications`).patchValue('C0', { emitEvent: false });

        } else {

          this.dataCollection_FilterLists[0] = [];
          if (!isUndefined(this.leftDataCollection.enumeration)) {

            if (!isUndefined(this.leftDataCollection.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`left.unitOptions.dataCollection`).patchValue(this.leftDataCollection.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.leftDataCollection.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`left.unitOptions.dataCollection`).patchValue(this.leftDataCollection.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.dataCollection`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.dataCollection`).patchValue('N', { emitEvent: false });
          }

          this.ta11_FilterLists[0] = [];
          if (!isUndefined(this.leftTa11.enumeration)) {

            if (!isUndefined(this.leftTa11.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`left.unitOptions.ta11`).patchValue(this.leftTa11.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.leftTa11.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`left.unitOptions.ta11`).patchValue(this.leftTa11.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.ta11`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.ta11`).patchValue('N', { emitEvent: false });
          }

          this.ta41_FilterLists[0] = [];
          if (!isUndefined(this.leftTa41.enumeration)) {

            if (!isUndefined(this.leftTa41.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`left.unitOptions.ta41`).patchValue(this.leftTa41.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.leftTa41.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`left.unitOptions.ta41`).patchValue(this.leftTa41.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.ta41`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.ta41`).patchValue('N', { emitEvent: false });
          }

          this.ta01_FilterLists[0] = [];
          if (!isUndefined(this.leftTa01.enumeration)) {

            if (!isUndefined(this.leftTa01.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`left.unitOptions.ta01`).patchValue(this.leftTa01.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.leftTa01.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`left.unitOptions.ta01`).patchValue(this.leftTa01.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.ta01`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.ta01`).patchValue('N', { emitEvent: false });
          }

          this.fa11_FilterLists[0] = [];
          if (!isUndefined(this.leftFa11.enumeration)) {

            if (!isUndefined(this.leftFa11.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`left.unitOptions.fa11`).patchValue(this.leftFa11.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.leftFa11.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`left.unitOptions.fa11`).patchValue(this.leftFa11.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.fa11`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.fa11`).patchValue('N', { emitEvent: false });
          }

          this.communications_FilterLists[0] = [];
          if (!isUndefined(this.leftCommunications.enumeration)) {

            if (!isUndefined(this.leftCommunications.enumeration.enumerationDefinitions.find(el => el.sequence == 1))) {
              this.resultsForm.get(`left.unitOptions.communications`).patchValue(this.leftCommunications.enumeration.enumerationDefinitions.find(el => el.sequence == 1).value, { emitEvent: false });
            }
            else if (!isUndefined(this.leftCommunications.enumeration.enumerationDefinitions.find(el => el.sequence == 2))) {
              this.resultsForm.get(`left.unitOptions.communications`).patchValue(this.leftCommunications.enumeration.enumerationDefinitions.find(el => el.sequence == 2).value, { emitEvent: false });
            }
            else {
              this.resultsForm.get(`left.unitOptions.communications`).patchValue('N', { emitEvent: false });
            }
          }
          else {
            this.resultsForm.get(`left.unitOptions.communications`).patchValue('N', { emitEvent: false });
          }

        }

      }
    }
      
    //////////////////////////////////////////////////////////////////////////////////////////////
    //                                       End Of Rules                                       //
    //                                      ==============                                      //
    //////////////////////////////////////////////////////////////////////////////////////////////
    this.unitOptionChanged.emit(this.getAllUnitOptionsValue(side));
  }


  private onUnitOptionChange(optionName: string, selectedValue: any, side?: string) {
    if (!this.isLoadingJob) {
      if (selectedValue != null) {

        var index = side == 'left' ? 0 : 1;

        switch (optionName) {

          case 'fa11':

            if (selectedValue !== 'Y' && selectedValue !== 'N') { //values 'Y' or 'N' are coming from the actual change in the fa11 option dropdown. Therfore any checks and field settings are handled in the option rules.
              //values 'true' or 'false' are coming from from the back end check that takes place during the sizing process. This check determin if the fa11 sensor is even available for the unit. Results of that check are handled in the code below. (method is being called within 'displayPricingResults' at line 858 -> as it stands on 28/08/2024  )
              if (selectedValue == false || this.fa11_FilterLists[index].length == 1) {
                if (selectedValue == true) {
                  if (this.fa11_FilterLists[index].length == 1) {

                    if (side === 'left') {
                      this.leftFa11.internalValue = this.fa11_FilterLists[index][0];
                    }
                    else if(side === 'right') {
                      this.rightFa11.internalValue = this.fa11_FilterLists[index][0];
                    }
                    
                    this.resultsForm.get(`${side}.unitOptions.fa11`).disable({ emitEvent: false });
                  }
                }
                else {
                  if (side === 'left') {
                    this.leftFa11.internalValue = 'N';
                  }
                  else if (side === 'right') {
                    this.rightFa11.internalValue = 'N';
                  }
                  this.resultsForm.get(`${side}.unitOptions.fa11`).disable({ emitEvent: false });
                }
              } else if (selectedValue == true) {
                this.resultsForm.get(`${side}.unitOptions.fa11`).enable({ emitEvent: false });
              }
            }
            break;

          case 'controlValveFeedback':
            if (side == 'left') {
              this.leftControlValveFeedback.internalValue = selectedValue;
            }
            else if (side == 'right') {
              this.rightControlValveFeedback.internalValue = selectedValue;
            }
            break;

          case 'extras':
            if (side == 'left') {
              this.leftExtras.internalValue = selectedValue;
            }
            else if (side == 'right') {
              this.rightExtras.internalValue = selectedValue;
            }
            break;

          case 'extras':
            if (side == 'left') {
              this.leftExtras.internalValue = selectedValue;
            }
            else if (side == 'right') {
              this.rightExtras.internalValue = selectedValue;
            }
            break;
        };

        //check if the change was automatic or manual
        let options = [
          'pa31',
          'ta31',
          'extras',
          'controlValveFeedback',
          'integrityTest',
          'fa11',
          'condensateRemoval',
          'gasketMaterial',
          'manualIsolation',
          'ups',
          'highLimit',
          'actuator',
          'ta11',
          'ta41',
          'ta01',
          'dataCollection',
          'controlSystem',
          'communications'
        ];

        if (options.includes(optionName)) {
          let index = side == 'left' ? 0 : 1;
          switch (optionName) {
            case 'controlSystem':
              if (this.controlSystem_TouchLists[index].includes(true)) {
                if (side == 'left') { this.leftControlSystem.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                if (side == 'right') { this.rightControlSystem.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                this.controlSystem_TouchLists = [[false], [false]];
                this.applyUnitsOptionsRules(side, optionName);
              }
              break;
            case 'communications':
              if (this.communications_TouchLists[index].includes(true)) {
                if (side == 'left') { this.leftCommunications.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                if (side == 'right') { this.rightCommunications.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                this.communications_TouchLists = [[false], [false]];
                this.applyUnitsOptionsRules(side, optionName);
              }
              break;
            case 'dataCollection':
              if (this.dataCollection_TouchLists[index].includes(true)) {
                if (side == 'left') { this.leftDataCollection.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                if (side == 'right') { this.rightDataCollection.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                this.dataCollection_TouchLists = [[false], [false]];
                this.applyUnitsOptionsRules(side, optionName);
              }
              break;
            case 'ta11':
              if (this.ta11_TouchLists[index].includes(true)) {
                if (side == 'left') { this.leftTa11.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                if (side == 'right') { this.rightTa11.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                this.ta11_TouchLists = [[false], [false]];
                this.applyUnitsOptionsRules(side, optionName);
              }
              break;
            case 'ta41':
              if (this.ta41_TouchLists[index].includes(true)) {
                if (side == 'left') { this.leftTa41.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                if (side == 'right') { this.rightTa41.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                this.ta41_TouchLists = [[false], [false]];
                this.applyUnitsOptionsRules(side, optionName);
              }
              break;
            case 'ta01':
              if (this.ta01_TouchLists[index].includes(true)) {
                if (side == 'left') { this.leftTa01.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                if (side == 'right') { this.rightTa01.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                this.ta01_TouchLists = [[false], [false]];
                this.applyUnitsOptionsRules(side, optionName);
              }
              break;
            case 'fa11':
              if (this.fa11_TouchLists[index].includes(true)) {
                if (side == 'left') { this.leftFa11.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                if (side == 'right') { this.rightFa11.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                this.fa11_TouchLists = [[false], [false]];
                this.applyUnitsOptionsRules(side, optionName);

              }
              break;
            case 'pa31':
              if (this.pa31_TouchLists[index].includes(true)) {
                if (side == 'left') { this.leftPa31.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                if (side == 'right') { this.rightPa31.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                this.pa31_TouchLists = [[false], [false]];
                this.applyUnitsOptionsRules(side, optionName);
              }
              break;
            case 'ta31':
              if (this.ta31_TouchLists[index].includes(true)) {
                if (side == 'left') { this.leftTa31.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                if (side == 'right') { this.rightTa31.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                this.ta31_TouchLists = [[false], [false]];
                this.applyUnitsOptionsRules(side, optionName);
              }
              break;
            case 'extras':
              if (this.extras_TouchLists[index].includes(true)) {
                if (side == 'left') { this.leftExtras.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                if (side == 'right') { this.rightExtras.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                this.extras_TouchLists = [[false], [false]];
                this.applyUnitsOptionsRules(side, optionName);
              }
              break;
            case 'controlValveFeedback':
              if (this.controlValveFeedback_TouchLists[index].includes(true)) {
                if (side == 'left') { this.leftControlValveFeedback.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                if (side == 'right') { this.rightControlValveFeedback.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                this.controlValveFeedback_TouchLists = [[false], [false]];
                this.applyUnitsOptionsRules(side, optionName);
              }
              break;
            case 'integrityTest':
              if (this.integrityTest_TouchLists[index].includes(true)) {
                if (side == 'left') { this.leftIntegrityTest.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                if (side == 'right') { this.rightIntegrityTest.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                this.integrityTest_TouchLists = [[false], [false]];
                this.applyUnitsOptionsRules(side, optionName);
              }
              break;
            case 'condensateRemoval':
              if (this.condensateRemoval_TouchLists[index].includes(true)) {
                if (selectedValue != this.actualCondensateRemovalValue[index]) {
                  this.actualCondensateRemovalValue[index] = selectedValue;
                  this.resultsForm.get(`${side}.unitOptions.condensateRemoval`).patchValue(selectedValue, { emitEvent: false });
                  this.condensateRemoval_TouchLists = [[false], [false]];
                  this.reSizeUnit.emit({ index: index, optionName: optionName, value: selectedValue });
                };
              }
              break;
            case 'gasketMaterial':
              if (this.gasketMaterial_TouchLists[index].includes(true)) {
                this.resultsForm.get(`${side}.unitOptions.gasketMaterial`).patchValue(selectedValue, { emitEvent: false });
                this.gasketMaterial_TouchLists = [[false], [false]];
                this.reSizeUnit.emit({ index: index, optionName: optionName, value: selectedValue });
              }
              break;
            case 'manualIsolation':
              if (this.manualIsolation_TouchLists[index].includes(true)) {
                if (side == 'left') { this.leftManualIsolation.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                if (side == 'right') { this.rightManualIsolation.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                this.manualIsolation_TouchLists = [[false], [false]];
                this.applyUnitsOptionsRules(side, optionName);
              }
              break;
            case 'ups':
              if (this.ups_TouchLists[index].includes(true)) {
                if (side == 'left') { this.leftUps.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                if (side == 'right') { this.rightUps.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                this.ups_TouchLists = [[false], [false]];
                this.applyUnitsOptionsRules(side, optionName);
              }
              break;
            case 'highLimit':
              if (this.highLimit_TouchLists[index].includes(true)) {
                if (side == 'left') { this.leftHighLimit.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                if (side == 'right') { this.rightHighLimit.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                this.highLimit_TouchLists = [[false], [false]];
                this.applyUnitsOptionsRules(side, optionName);
              }
              break;
            case 'actuator':
              if (this.actuator_TouchLists[index].includes(true)) {
                if (side == 'left') { this.leftActuator.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                if (side == 'right') { this.rightActuator.enumeration.enumerationDefinitions.forEach(el => el.value == selectedValue ? el.sequence = 1 : el.sequence += 1); }
                this.actuator_TouchLists = [[false], [false]];
                this.applyUnitsOptionsRules(side, optionName);
              }
              break;
            
          }
        }
        else this.applyUnitsOptionsRules(side, optionName);

        const control = this.resultsForm.get(`${side}.unitOptions`);

        if (control && control.touched && control.dirty) {
          control.markAsUntouched();
          control.markAsPristine();
        }
        this.controlSystem_TouchLists = [[false], [false]];
        this.dataCollection_TouchLists = [[false], [false]];
        this.ta11_TouchLists = [[false], [false]];
        this.ta41_TouchLists = [[false], [false]];
        this.ta01_TouchLists = [[false], [false]];
        this.fa11_TouchLists = [[false], [false]];
        this.ta31_TouchLists = [[false], [false]];
        this.pa31_TouchLists = [[false], [false]];
        this.extras_TouchLists = [[false], [false]];
        this.controlValveFeedback_TouchLists = [[false], [false]];
        this.integrityTest_TouchLists = [[false], [false]];
        this.condensateRemoval_TouchLists = [[false], [false]];
        this.gasketMaterial_TouchLists = [[false], [false]];
        this.manualIsolation_TouchLists = [[false], [false]];
        this.ups_TouchLists = [[false], [false]];
        this.highLimit_TouchLists = [[false], [false]];
        this.actuator_TouchLists = [[false], [false]];
        this.communications_TouchLists = [[false], [false]];

      }
    }
  }

  pushInitialEnumerationValuesTotheForm(side: string, option: EHPricingOptions[]) {
    this.resultsForm.get(`${side}.unitOptions.actuator`).patchValue(option.find(opt => opt.EnumerationName.includes('Actuator')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.gasketMaterial`).patchValue(option.find(opt => opt.EnumerationName.includes('GasketMaterial')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.condensateRemoval`).patchValue(option.find(opt => opt.EnumerationName.includes('CondensateRemoval')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.highLimit`).patchValue(option.find(opt => opt.EnumerationName.includes('HighLimit')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.manualIsolation`).patchValue(option.find(opt => opt.EnumerationName.includes('ManualIsolation')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.systemRecirculation`).patchValue(option.find(opt => opt.EnumerationName.includes('SystemRecirculation')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.controlSystem`).patchValue(option.find(opt => opt.EnumerationName.includes('ControlSystem')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.frameType`).patchValue(option.find(opt => opt.EnumerationName.includes('FrameType')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.packageSupports`).patchValue(option.find(opt => opt.EnumerationName.includes('PackageSupports')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.panelLocation`).patchValue(option.find(opt => opt.EnumerationName.includes('PanelLocation')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.extras`).patchValue(option.find(opt => opt.EnumerationName.includes('Extras')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.integrityTest`).patchValue(option.find(opt => opt.EnumerationName.includes('IntegrityTest')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.dataCollection`).patchValue(option.find(opt => opt.EnumerationName.includes('DataCollection')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.controlValveFeedback`).patchValue(option.find(opt => opt.EnumerationName.includes('ControlValveFeedback')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.ta11`).patchValue(option.find(opt => opt.EnumerationName.includes('TA11')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.ta31`).patchValue(option.find(opt => opt.EnumerationName.includes('TA31')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.ta41`).patchValue(option.find(opt => opt.EnumerationName.includes('TA41')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.ta01`).patchValue(option.find(opt => opt.EnumerationName.includes('TA01')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.pa31`).patchValue(option.find(opt => opt.EnumerationName.includes('PA31')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.fa11`).patchValue(option.find(opt => opt.EnumerationName.includes('FA11')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.ups`).patchValue(option.find(opt => opt.EnumerationName.includes('UPS')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.communications`).patchValue(option.find(opt => opt.EnumerationName.includes('Communications')).SelectedValue, { emitEvent: false });
    this.resultsForm.get(`${side}.unitOptions.insulation`).patchValue(option.find(opt => opt.EnumerationName.includes('Insulation')).SelectedValue, { emitEvent: false });
  }

  getAllUnitOptionsValue(side: string): { options: EHPricingOptions[], side: string } {
    var options: EHPricingOptions[] = [];
    (async () => {
      if (side === 'left') {
        options.push({ EnumerationName: this.leftGasketMaterial.enumerationName, SelectedValue: this.leftGasketMaterial.internalValue })
        options.push({ EnumerationName: this.leftActuator.enumerationName, SelectedValue: this.leftActuator.internalValue })
        options.push({ EnumerationName: this.leftCondensateRemoval.enumerationName, SelectedValue: this.leftCondensateRemoval.internalValue })
        options.push({ EnumerationName: this.leftHighLimit.enumerationName, SelectedValue: this.leftHighLimit.internalValue })
        options.push({ EnumerationName: this.leftManualIsolation.enumerationName, SelectedValue: this.leftManualIsolation.internalValue })
        options.push({ EnumerationName: this.leftSystemRecirculation.enumerationName, SelectedValue: this.leftSystemRecirculation.internalValue })
        options.push({ EnumerationName: this.leftControlSystem.enumerationName, SelectedValue: this.leftControlSystem.internalValue })
        options.push({ EnumerationName: this.leftFrameType.enumerationName, SelectedValue: this.leftFrameType.internalValue })
        options.push({ EnumerationName: this.leftPackageSupports.enumerationName, SelectedValue: this.leftPackageSupports.internalValue })
        options.push({ EnumerationName: this.leftPanelLocation.enumerationName, SelectedValue: this.leftPanelLocation.internalValue })
        options.push({ EnumerationName: this.leftExtras.enumerationName, SelectedValue: this.leftExtras.internalValue })
        options.push({ EnumerationName: this.leftIntegrityTest.enumerationName, SelectedValue: this.leftIntegrityTest.internalValue })
        options.push({ EnumerationName: this.leftDataCollection.enumerationName, SelectedValue: this.leftDataCollection.internalValue })
        options.push({ EnumerationName: this.leftControlValveFeedback.enumerationName, SelectedValue: this.leftControlValveFeedback.internalValue })
        options.push({ EnumerationName: this.leftTa11.enumerationName, SelectedValue: this.leftTa11.internalValue })
        options.push({ EnumerationName: this.leftTa31.enumerationName, SelectedValue: this.leftTa31.internalValue })
        options.push({ EnumerationName: this.leftTa41.enumerationName, SelectedValue: this.leftTa41.internalValue })
        options.push({ EnumerationName: this.leftTa01.enumerationName, SelectedValue: this.leftTa01.internalValue })
        options.push({ EnumerationName: this.leftPa31.enumerationName, SelectedValue: this.leftPa31.internalValue })
        options.push({ EnumerationName: this.leftFa11.enumerationName, SelectedValue: this.leftFa11.internalValue })
        options.push({ EnumerationName: this.leftUps.enumerationName, SelectedValue: this.leftUps.internalValue })
        options.push({ EnumerationName: this.leftCommunications.enumerationName, SelectedValue: this.leftCommunications.internalValue })
        options.push({ EnumerationName: this.leftInsulation.enumerationName, SelectedValue: this.leftInsulation.internalValue })
      }

      if (side === 'right') {
        options.push({ EnumerationName: this.rightGasketMaterial.enumerationName, SelectedValue: this.rightGasketMaterial.internalValue })
        options.push({ EnumerationName: this.rightActuator.enumerationName, SelectedValue: this.rightActuator.internalValue })
        options.push({ EnumerationName: this.rightCondensateRemoval.enumerationName, SelectedValue: this.rightCondensateRemoval.internalValue })
        options.push({ EnumerationName: this.rightHighLimit.enumerationName, SelectedValue: this.rightHighLimit.internalValue })
        options.push({ EnumerationName: this.rightManualIsolation.enumerationName, SelectedValue: this.rightManualIsolation.internalValue })
        options.push({ EnumerationName: this.rightSystemRecirculation.enumerationName, SelectedValue: this.rightSystemRecirculation.internalValue })
        options.push({ EnumerationName: this.rightControlSystem.enumerationName, SelectedValue: this.rightControlSystem.internalValue })
        options.push({ EnumerationName: this.rightFrameType.enumerationName, SelectedValue: this.rightFrameType.internalValue })
        options.push({ EnumerationName: this.rightPackageSupports.enumerationName, SelectedValue: this.rightPackageSupports.internalValue })
        options.push({ EnumerationName: this.rightPanelLocation.enumerationName, SelectedValue: this.rightPanelLocation.internalValue })
        options.push({ EnumerationName: this.rightExtras.enumerationName, SelectedValue: this.rightExtras.internalValue })
        options.push({ EnumerationName: this.rightIntegrityTest.enumerationName, SelectedValue: this.rightIntegrityTest.internalValue })
        options.push({ EnumerationName: this.rightDataCollection.enumerationName, SelectedValue: this.rightDataCollection.internalValue })
        options.push({ EnumerationName: this.rightControlValveFeedback.enumerationName, SelectedValue: this.rightControlValveFeedback.internalValue })
        options.push({ EnumerationName: this.rightTa11.enumerationName, SelectedValue: this.rightTa11.internalValue })
        options.push({ EnumerationName: this.rightTa31.enumerationName, SelectedValue: this.rightTa31.internalValue })
        options.push({ EnumerationName: this.rightTa41.enumerationName, SelectedValue: this.rightTa41.internalValue })
        options.push({ EnumerationName: this.rightTa01.enumerationName, SelectedValue: this.rightTa01.internalValue })
        options.push({ EnumerationName: this.rightPa31.enumerationName, SelectedValue: this.rightPa31.internalValue })
        options.push({ EnumerationName: this.rightFa11.enumerationName, SelectedValue: this.rightFa11.internalValue })
        options.push({ EnumerationName: this.rightUps.enumerationName, SelectedValue: this.rightUps.internalValue })
        options.push({ EnumerationName: this.rightCommunications.enumerationName, SelectedValue: this.rightCommunications.internalValue })
        options.push({ EnumerationName: this.rightInsulation.enumerationName, SelectedValue: this.rightInsulation.internalValue })
      }
    }
    )();
    return ({ options: options, side: side })
  }

  onUnitSelected(value: any, side: string, otherSide: string) {
    this.specificationSheetValuesCollection = new SpecificationSheetValuesCollection();
    if (this.resultsForm.get(`${side}.pricingOptions.unitSelected`).value == true) {
      if (value == true) {
        this.resultsForm.get(`${otherSide}.pricingOptions.unitSelected`).patchValue(false, { emitEvent: false });
        this.updateSpecSheetValues.emit({ side: side });

        //user selected / check the box so we have to update Job Status from 2 (Calculated) to 3 (Selected) 
        this.updateJobStatusId.emit({ jobStatusId: 3 });
      }
    }
    // user de-selected / un-check the box so we have to update Job Status from 3 (Selected) to 2 (Calculated)
    if (value == false) {
      this.updateJobStatusId.emit({ jobStatusId: 2 });
    }
  }

  onPricingOptionChange(side: string) {
    this.pricingOptionChanged.emit({ form: this.resultsForm.get(`${side}.pricingOptions`).value, side: side });
  }

  getAppTypeHeaderName(unitType: string): string {
    var appName: string;

    switch (unitType) {

      case "SC":
        appName = this.translatePipe.transform("STEAM_CONTROL", false);
        return appName;

      case "DC":
        appName = this.translatePipe.transform("DUAL_CONTROL", false);
        return appName;

      case "CC":
        appName = this.translatePipe.transform("CONDENSATE_CONTROL", false);
        return appName;

    }
  }

  clearEnumerationsFilterLists(index?: number) {
    if (index) {
      this.gasketMaterial_FilterLists[index] = [];
      this.actuator_FilterLists[index] = [];
      this.condensateRemoval_FilterLists[index] = [];
      this.highLimit_FilterLists[index] = [];
      this.manualIsolation_FilterLists[index] = [];
      this.systemRecirculation_FilterLists[index] = [];
      this.controlSystem_FilterLists[index] = [];
      this.frameType_FilterLists[index] = [];
      this.packageSupports_FilterLists[index] = [];
      this.panelLocation_FilterLists[index] = [];
      this.extras_FilterLists[index] = [];
      this.integrityTest_FilterLists[index] = [];
      this.dataCollection_FilterLists[index] = [];
      this.controlValveFeedback_FilterLists[index] = [];
      this.ta11_FilterLists[index] = [];
      this.ta31_FilterLists[index] = [];
      this.ta41_FilterLists[index] = [];
      this.ta01_FilterLists[index] = [];
      this.pa31_FilterLists[index] = [];
      this.fa11_FilterLists[index] = [];
      this.ups_FilterLists[index] = [];
      this.communications_FilterLists[index] = [];
      this.insulation_FilterLists[index] = [];
    } else {
      this.gasketMaterial_FilterLists = [[], []];
      this.actuator_FilterLists = [[], []];
      this.condensateRemoval_FilterLists = [[], []];
      this.highLimit_FilterLists = [[], []];
      this.manualIsolation_FilterLists = [[], []];
      this.systemRecirculation_FilterLists = [[], []];
      this.controlSystem_FilterLists = [[], []];
      this.frameType_FilterLists = [[], []];
      this.packageSupports_FilterLists = [[], []];
      this.panelLocation_FilterLists = [[], []];
      this.extras_FilterLists = [[], []];
      this.integrityTest_FilterLists = [[], []];
      this.dataCollection_FilterLists = [[], []];
      this.controlValveFeedback_FilterLists = [[], []];
      this.ta11_FilterLists = [[], []];
      this.ta31_FilterLists = [[], []];
      this.ta41_FilterLists = [[], []];
      this.ta01_FilterLists = [[], []];
      this.pa31_FilterLists = [[], []];
      this.fa11_FilterLists = [[], []];
      this.ups_FilterLists = [[], []];
      this.communications_FilterLists = [[], []];
      this.insulation_FilterLists = [[], []];
    }

  }

  resetUnitOptionPreferencesToTheirOriginalSettings() {
    this.sizingApi.getTranslatedDisplayGroup('EASIHEAT_GEN4_SIZING').subscribe((result: DisplayGroup) => {
      this.translationService.displayGroup.enumerations = result.enumerations;
    });
  }

  setCurrencySymbol(symbol: string) {
    this.currencySymbol[0] = symbol;
    this.hasCurrencySymbol = true;
  }

  clearEnergySavingUIvalues() {
    //Energy Saving Interpolation Values and Units
    // Units
    this.steamMassSaved_Unit = "";
    this.co2eMassSaving_Unit = "";
    this.flashEnergySaving_Unit = "";
    // Values left
    this.leftUnitType_Value = '';
    this.leftSteamConsumptionReductionPercent_Value = null;
    this.leftSteamMassSaved_Value = null;
    this.leftCo2eEmissionReductionPerYear_Value = null;
    this.leftCo2eEmissionCostReductionPerYear_Value = null;
    this.leftEnergySavedPerYear_Value = null;
    this.leftEnergyCostReductionPerYear_Value = null;
    this.leftWaterSavedPerYear_Value = null;
    this.leftWaterCostReductionPerYear_Value = null;
    this.leftTotalEvcCostReductionPerYear_Value = null;
    //Values right
    this.rightUnitType_Value = '';
    this.rightSteamConsumptionReductionPercent_Value = null;
    this.rightSteamMassSaved_Value = null;
    this.rightCo2eEmissionReductionPerYear_Value = null;
    this.rightCo2eEmissionCostReductionPerYear_Value = null;
    this.rightEnergySavedPerYear_Value = null;
    this.rightEnergyCostReductionPerYear_Value = null;
    this.rightWaterSavedPerYear_Value = null;
    this.rightWaterCostReductionPerYear_Value = null;
    this.rightTotalEvcCostReductionPerYear_Value = null;
    //Unit translations
    this.emissionUnitTranslated = '';
    this.energyUnitTranslated = '';
    this.massFlowUnitTranslated = '';
    this.volumeUnitTranslated = '';
    this.temperatureUnitTranslated = '';
  }

  setEnergySavingUnitTranslations(emission: DisplayPreferenceDirective, energy: DisplayPreferenceDirective, volume: DisplayPreferenceDirective, temperature: DisplayPreferenceDirective) {
    this.emissionUnitTranslated = this.translatePipe.transform(emission.preference.masterTextKey, false);
    this.energyUnitTranslated = this.translatePipe.transform(energy.preference.masterTextKey, false);
    this.volumeUnitTranslated = this.translatePipe.transform(volume.preference.masterTextKey, false);
    this.temperatureUnitTranslated = this.translatePipe.transform(temperature.preference.masterTextKey, false);
  }

  nomenclatureFunction(side: string) {
    if (side === 'left') {

      this.rightNomenclature = false;
      this.leftNomenclature = !this.leftNomenclature;
    }
    if (side === 'right') {

      this.leftNomenclature = false;
      this.rightNomenclature = !this.rightNomenclature;
    }

  }

  getFuelInfo(): EnergySavingsFuelInfoInterface {
    if (this.performEnergySavingCalc) {
      let fuelInfo = <EnergySavingsFuelInfoInterface> { 
        fuelCalorificValue: +this.fuelKcalValue,
        fuelCalorificValueUnit: this.fuelKcalUnit,
        fuelCO2eEmissions: +this.fuelCO2eValue,
        fuelCO2eEmmissionUnit: this.fuelCO2eUnit
      }
      
      return fuelInfo;
    }
    else return null;
  }

  setUnitsOptionsRulesToModulePrefBeforeInitialPricingCall(modPrefOpt: EHPricingOptions[], easiHeatUnit: string, cvSize : string): EHPricingOptions[] {

    //Process Coditions
    const pc: ProcessConditionsFormGroup = this.processConditions;
    //SizingResultLeft
    const lt: EasiHeatUnitFormGroup = this.resultsForm.get('left').value;

    //HEATING (HTG)///////////////////////////////////////////////////////////
    /*************************************************************************
     *EHHDC*
     *******/

    if (easiHeatUnit == 'EHHDC') {
      //Actuation Rule 3 + designPressure12Bar rule
      if (cvSize == '5') {
        if (this.convertedToBargPressure > 8.8 || (pc.designPressure12Bar && modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_HighLimit').SelectedValue == 'N')) {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_Actuator').SelectedValue = 'PN';
        }
      }
      if (cvSize == '6') {

        //Actuation Rule 4 + designPressure12Bar rule
        if (this.convertedToBargPressure > 5.3 || pc.designPressure12Bar) {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_Actuator').SelectedValue = 'PN';

          //High Limit Rule 4 - it must sits with in Actuation Rule 4 + designPressure12Bar rule
          if (pc.designPressure12Bar) {
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_HighLimit').SelectedValue = 'SIHL';
          }
          else if (this.convertedToBargPressure > 6.9) {
            if (
              modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_HighLimit').SelectedValue != 'N' &&
              modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_HighLimit').SelectedValue != 'SIHL'
            )

              modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_HighLimit').SelectedValue = 'N'
          }

        }
      }
      //Manual Isolation Rules
      if (cvSize == '5') {

        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_ManualIsolation').SelectedValue != 'N' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_ManualIsolation').SelectedValue != 'V2') {

        }
        modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_ManualIsolation').SelectedValue = 'N'
      }

      if (cvSize == '6') {
        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_ManualIsolation').SelectedValue != 'N' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_ManualIsolation').SelectedValue != 'V1' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_ManualIsolation').SelectedValue != 'V2') {

        }
        modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_ManualIsolation').SelectedValue = 'N'
      }

      //High Limit Rule 3
      if (cvSize == '6') {
        if (this.convertedToBargPressure > 6.9) {
          if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_Actuator').SelectedValue == 'EL') {
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_HighLimit').SelectedValue = 'N'
          }
        }
      }

      //////////////////////////////////
      // Actuation Rules under HTG DC //
      //////////////////////////////////
      //  Actuation set to EL //
      //////////////////////////

      if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_Actuator').SelectedValue == 'EL') {

        modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_ControlValveFeedback').SelectedValue = 'CVF';

        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_Extras').SelectedValue != 'N' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_Extras').SelectedValue != 'GP') {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_Extras').SelectedValue = 'N';
        }

        //////////////////////
        // UPS Rule with EL //
        //////////////////////

        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_HighLimit').SelectedValue == 'N') {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_UPS').SelectedValue == 'Y'
        }
      } else if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_DC_Actuator').SelectedValue == 'PN') {
        /////////////////////////
        // Actuation set to PN //
        /////////////////////////

        //No need to change anything here; 

      }
    }
    
    /************************************************************************
    *EHHCC*
    *******/
    if (easiHeatUnit == 'EHHCC') {

      if (cvSize == '2' && this.htgCcInletPipeVeloctiyOver40ms) {

        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_CC_ManualIsolation').SelectedValue != 'N' &&
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_CC_ManualIsolation').SelectedValue != 'V2')
        {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_CC_ManualIsolation').SelectedValue = 'N'
        }
      }

      if (cvSize == '3') {

        if (pc.designPressure12Bar) {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_CC_Actuator').SelectedValue = 'PN';
          if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_CC_HighLimit').SelectedValue != 'N' &&
              modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_CC_HighLimit').SelectedValue != 'SIHL') {
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_CC_HighLimit').SelectedValue = 'N'
          }
          
        }

        if (this.htgCcInletPipeVeloctiyOver40ms) {
          if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_CC_ManualIsolation').SelectedValue != 'N' &&
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_CC_ManualIsolation').SelectedValue != 'V1' &&
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_CC_ManualIsolation').SelectedValue != 'V2') {
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_CC_ManualIsolation').SelectedValue = 'N'
          }
        }
        
      }

      //////////////////////////////////
      // Actuation Rules under HTG CC //
      //////////////////////////////////
      //  Actuation set to EL //
      //////////////////////////

      if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_CC_Actuator').SelectedValue == 'EL') {

        modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_CC_ControlValveFeedback').SelectedValue = 'CVF';

        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_CC_Extras').SelectedValue != 'N' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_CC_Extras').SelectedValue != 'GP') {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_CC_Extras').SelectedValue = 'N';
        }

      }
      else if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_CC_Actuator').SelectedValue == 'PN') {
        /////////////////////////
        // Actuation set to PN //
        /////////////////////////

        // No need to do anything here
      }
    }

    /************************************************************************
    *EHHSC*
    *******/
    if (easiHeatUnit == 'EHHSC') {

      //Control System Rule
      if (pc.globalVariance != 'UK') {
        modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ControlSystem').SelectedValue = 'P1';
      }


      //Actuation Rules
      if (cvSize == '5') {
        if (this.convertedToBargPressure > 8.8 || (pc.designPressure12Bar && modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_HighLimit').SelectedValue == 'N')) {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_Actuator').SelectedValue = 'PN';
        }
      }
      if (cvSize == '6') {
        if (this.convertedToBargPressure > 5.3 || pc.designPressure12Bar) {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_Actuator').SelectedValue = 'PN';

          if (pc.designPressure12Bar) {
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_HighLimit').SelectedValue = 'SIHL';
          }
        }
      }


      //Manual Isolation Rules
      if (lt.heatExchangePlateType == 'T10M' && lt.steamCvSplitRangeKv != '0') {// if  !='0'  is true, it means that the unit is Split Range 
        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ManualIsolation').SelectedValue != 'N' &&
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ManualIsolation').SelectedValue != 'V1' &&
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ManualIsolation').SelectedValue != 'V2')
        {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ManualIsolation').SelectedValue = 'N';
        }
      }
      if (cvSize == '4' && lt.steamCvSplitRangeKv != '0') {// if  !='0'  is true, it means that the unit is Split Range 
        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ManualIsolation').SelectedValue != 'N' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ManualIsolation').SelectedValue != 'V2')
        {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ManualIsolation').SelectedValue = 'N';
        }
      }
      if (cvSize == '5' && lt.steamCvSplitRangeKv == '0') {// if  =='0'  is true, it means that the unit is NOT a Split Range 
        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ManualIsolation').SelectedValue != 'N' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ManualIsolation').SelectedValue != 'V2')
        {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ManualIsolation').SelectedValue = 'N';
        }
      }
      if (cvSize == '5' && lt.steamCvSplitRangeKv != '0') {// if  !='0'  is true, it means that the unit is Split Range 
        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ManualIsolation').SelectedValue != 'N' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ManualIsolation').SelectedValue != 'V1' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ManualIsolation').SelectedValue != 'V2')
        {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ManualIsolation').SelectedValue = 'N';
        }
      }
      if (cvSize == '6') {
        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ManualIsolation').SelectedValue != 'N' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ManualIsolation').SelectedValue != 'V1' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ManualIsolation').SelectedValue != 'V2')
        {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ManualIsolation').SelectedValue = 'N';
        }
      }
      //High Limit Rules

      if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ControlSystem').SelectedValue == 'B1') { //this rule came from new Control System Option 'GSX Process Panel'
        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_HighLimit').SelectedValue != 'SIHL' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_HighLimit').SelectedValue != 'IHL')
        {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_HighLimit').SelectedValue = 'SIHL'
        }
        
      }

      if (cvSize == '5') {
        if (this.convertedToBargPressure > 6.9) {//rule 6
          if (lt.steamCvSplitRangeKv != '0') {// if  !='0'  is true, it means that the unit is Split Range ) {

            if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_Actuator').SelectedValue == 'EL' &&
                modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ControlSystem').SelectedValue != 'B1') {
              modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_HighLimit').SelectedValue = 'N';
            }
            else {
              if ((modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_HighLimit').SelectedValue == 'N' &&
                  modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_HighLimit').SelectedValue == 'SHIL') &&
                  modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ControlSystem').SelectedValue != 'B1')
              {
                modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_HighLimit').SelectedValue = 'N';
              }
            }
          }
        }
      }

      if (cvSize == '6') {
        if (lt.steamCvSplitRangeKv != '0') {// rule 7  // if  !='0'  is true, it means that the unit is Split Range )

          if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_Actuator').SelectedValue == 'EL' &&
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ControlSystem').SelectedValue != 'B1') {
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_HighLimit').SelectedValue = 'N';
          } else {
            if ((modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_HighLimit').SelectedValue == 'N' &&
                modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_HighLimit').SelectedValue == 'SHIL') &&
                modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ControlSystem').SelectedValue != 'B1')
            {
              modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_HighLimit').SelectedValue = 'N';
            }
          }
        }
        else {
          if (this.convertedToBargPressure > 6.9) {//rule 5
            if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_Actuator').SelectedValue == 'EL') {
              modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_HighLimit').SelectedValue = 'N';
            }
            else {//rule 8
              if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_HighLimit').SelectedValue == 'N' &&
                modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_HighLimit').SelectedValue == 'INAIL' &&
                modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_HighLimit').SelectedValue == 'SHIL')
              {
                modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_HighLimit').SelectedValue = 'N';
              }
            }
          }
        }

        if (pc.designPressure12Bar) {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_HighLimit').SelectedValue = 'SHIL'
        }

      }

      //////////////////////////////////
      // Actuation Rules under HTG SC //
      //////////////////////////////////
      //  Actuation set to EL //
      //////////////////////////
      if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_Actuator').SelectedValue == 'EL') {

          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ControlValveFeedback').SelectedValue = 'CVF';

        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_Extras').SelectedValue != 'N' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_Extras').SelectedValue != 'GP')
        {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_Extras').SelectedValue = 'N';
        }

        //////////////////////
        // UPS Rule with EL //
        //////////////////////
        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ControlSystem').SelectedValue == 'B1') {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_UPS').SelectedValue = 'N'
        }
        else if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_HighLimit').SelectedValue == 'N') {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_UPS').SelectedValue = 'Y'
        }

      } else if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_Actuator').SelectedValue == 'PN') {
        /////////////////////////
        // Actuation set to PN //
        /////////////////////////

        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ControlSystem').SelectedValue == 'B1') {

          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ControlValveFeedback').SelectedValue = 'N';

          if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_Extras').SelectedValue != 'N' ||
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_Extras').SelectedValue != 'GP')
          {
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_Extras').SelectedValue = 'N';
          }

          modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_UPS').SelectedValue = 'N'

        }
      }

      //////////////////////////
      // Control System Rules //
      //////////////////////////
      if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ControlSystem').SelectedValue == 'B1') {

        modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_IntegrityTest').SelectedValue = 'N';
        modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_DataCollection').SelectedValue = 'N';
        modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_Communications').SelectedValue = 'C0';
        modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_FA11').SelectedValue = 'N';
        modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_PA31').SelectedValue = 'N';
        modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_TA11').SelectedValue = 'N';
        modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_TA31').SelectedValue = 'N';
        modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_TA41').SelectedValue = 'N';
        modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_TA01').SelectedValue = 'N';

      }

    }


    //DOMESTIC HOT WATER (DHW)////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////
    // EHDDC //
    ///////////

    if (easiHeatUnit == 'EHDDC') {

      //Actuation Rule 3  + designPressure12Bar rule
      if (cvSize == '5') {
        if (this.convertedToBargPressure > 8.8 || (pc.designPressure12Bar && modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_HighLimit').SelectedValue == 'N')) {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_Actuator').SelectedValue = 'PN';
        }
      }
      //Actuation Rule 4 + designPressure12Bar rule
      if (cvSize == '6') {
        if (this.convertedToBargPressure > 5.3 || pc.designPressure12Bar) {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_Actuator').SelectedValue = 'PN';

          //High Limit Rule 4 - it must sits with in Actuation Rule 4 + designPressure12Bar rule
          if (pc.designPressure12Bar) {
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_HighLimit').SelectedValue = 'SIHL';
          }
          else if (this.convertedToBargPressure > 6.9) {
            if (
              modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_HighLimit').SelectedValue != 'N' &&
              modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_HighLimit').SelectedValue != 'SIHL'
            )
              modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_HighLimit').SelectedValue = 'N';
          }
        }
      }

      //Manual Isolation Rules
      if (cvSize == '5') {
        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_ManualIsolation').SelectedValue != 'N' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_ManualIsolation').SelectedValue != 'V2')
        {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_ManualIsolation').SelectedValue = 'N';
        }
      }

      if (cvSize == '6') {
        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_ManualIsolation').SelectedValue != 'N' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_ManualIsolation').SelectedValue != 'V1' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_ManualIsolation').SelectedValue != 'V2')
        {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_ManualIsolation').SelectedValue = 'N';
        }
      }

      //High Limit Rule 3
      if (cvSize == '6') {
        if (this.convertedToBargPressure > 6.9) {
          if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_Actuator').SelectedValue == 'EL') {
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_HighLimit').SelectedValue = 'N'
          }
        }
      }

      //////////////////////////////////
      // Actuation Rules under DHW DC //
      //////////////////////////////////
      //  Actuation set to EL //
      //////////////////////////
      if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_Actuator').SelectedValue == 'EL') {

        modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_IntegrityTest').SelectedValue = 'N'

        modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_ControlValveFeedback').SelectedValue = 'CVF';

        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_Extras').SelectedValue != 'N' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_Extras').SelectedValue != 'GP')
        {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_Extras').SelectedValue = 'N';
        }
        

        //////////////////////
        // UPS Rule with EL //
        //////////////////////
        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_HighLimit').SelectedValue == 'N') {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_UPS').SelectedValue == 'Y'
        }

      }
      else if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_Actuator').SelectedValue == 'PN') {
        /////////////////////////
        // Actuation set to PN //
        /////////////////////////

        ////////////////////////////////////////////////////////////////
        //Integrity Test Rules under DHW SC with PN actuator selected //
        ////////////////////////////////////////////////////////////////
        //Integrity Test set to YES //
        //////////////////////////////
        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_IntegrityTest').SelectedValue == 'T') {

          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_PA31').SelectedValue = 'Y';
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_TA31').SelectedValue = 'Y';

          if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_Extras').SelectedValue != 'AP' &&
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_Extras').SelectedValue != 'GPAP')
          {
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_Extras').SelectedValue = 'AP';
          }
        }
        else if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_DC_IntegrityTest').SelectedValue == 'N') {

         // No need to do anything here
        }
      }
    }


    ////////////////////////////////////////////////////////////////////////
    // EHDSC //
    ///////////
    if (easiHeatUnit == 'EHDSC') {

      //Control System Rule
      if (pc.globalVariance != 'UK') {
        modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ControlSystem').SelectedValue = 'P1';
      }

      //Actuation Rules
      if (cvSize == '5') {
        if (this.convertedToBargPressure > 8.8 || (pc.designPressure12Bar && modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue == 'N')) {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_Actuator').SelectedValue = 'PN';
        }
      }
      if (cvSize == '6') {
        if (this.convertedToBargPressure > 5.3 || pc.designPressure12Bar) {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_Actuator').SelectedValue = 'PN';

          if (pc.designPressure12Bar) {
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue = 'SIHL';
          }

        }
      }

      //Manual Isolation Rules
      if (lt.heatExchangePlateType == 'T10M' && lt.steamCvSplitRangeKv != '0') {// if  !='0'  is true, it means that the unit is Split Range
        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ManualIsolation').SelectedValue != 'N' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ManualIsolation').SelectedValue != 'V1' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ManualIsolation').SelectedValue != 'V2')
        {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ManualIsolation').SelectedValue = 'N';
        }
      }
      if (cvSize == '4' && lt.steamCvSplitRangeKv != '0') {// if  !='0'  is true, it means that the unit is Split Range
        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ManualIsolation').SelectedValue != 'N' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ManualIsolation').SelectedValue != 'V2')
        {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ManualIsolation').SelectedValue = 'N';
        }
      }
      if (cvSize == '5') {
        if (lt.steamCvSplitRangeKv != '0') {// if  !='0'  is true, it means that the unit is Split Range )
          if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ManualIsolation').SelectedValue != 'N' &&
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ManualIsolation').SelectedValue != 'V1' &&
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ManualIsolation').SelectedValue != 'V2')
          {
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ManualIsolation').SelectedValue = 'N';
          }
        }
        else { //cvSize 5 without Split Range
          if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ManualIsolation').SelectedValue != 'N' &&
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ManualIsolation').SelectedValue != 'V2')
          {
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ManualIsolation').SelectedValue = 'N';
          }

          if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ManualIsolation').SelectedValue == 'V1') {
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ManualIsolation').SelectedValue = 'N';
          }
        }
      }
      if (cvSize == '6') {
        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ManualIsolation').SelectedValue != 'N' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ManualIsolation').SelectedValue != 'V1' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ManualIsolation').SelectedValue != 'V2')
        {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ManualIsolation').SelectedValue = 'N';
        }
      }


      //High Limit Rules

      if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ControlSystem').SelectedValue == 'B1') { //this rule came from new Control System Option 'GSX Process Panel'
        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue != 'SIHL' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue != 'IHL')
        {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue = 'SIHL'
        }

      }

      if (cvSize == '5') {
        if (this.convertedToBargPressure > 6.9) {//rule 6
          if (lt.steamCvSplitRangeKv != '0') {// if  !='0'  is true, it means that the unit is Split Range )

            if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_Actuator').SelectedValue == 'EL' &&
                modPrefOpt.find(opt => opt.EnumerationName == 'EH_HTG_SC_ControlSystem').SelectedValue != 'B1') {
              modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue = 'N';
            }
            else { //rule 9
              if ((modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue != 'N' &&
                   modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue != 'SHIL') &&
                   modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ControlSystem').SelectedValue != 'B1')
              {
                modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue = 'N';
              }
            }
          }
        }
      }

      if (cvSize == '6') {
        if (lt.steamCvSplitRangeKv != '0') {// rule 7 // if  !='0'  is true, it means that the unit is Split Range )

          if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_Actuator').SelectedValue == 'EL' &&
              modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ControlSystem').SelectedValue != 'B1')
          {
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue = 'N';
          }
          else { //rule 9
            if ((modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue != 'N' &&
                 modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue != 'SHIL') &&
                 modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ControlSystem').SelectedValue != 'B1')
            {
              modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue = 'N';
            }
          }
        }
        else {
          if (this.convertedToBargPressure > 6.9) { //rule 5
            if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_Actuator').SelectedValue == 'EL' &&
                modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ControlSystem').SelectedValue != 'B1')
            {
              modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue = 'N';
            }
            else { //rule 8
              if ((modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue != 'N' &&
                   modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue != 'SIHL' &&
                   modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue != 'INAIL') &&
                   modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ControlSystem').SelectedValue != 'B1')
              {
                modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue = 'N';
              }
            }
          }
        }

        if (pc.designPressure12Bar) {
          if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ControlSystem').SelectedValue != 'B1' &&
            (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue != 'N' ||
              modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue != 'SIHL')) {
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue = 'N'
          }
          else {
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue = 'SIHL';
          }

        }


      }

      //////////////////////////////////
      // Actuation Rules under DHW SC //
      //////////////////////////////////
      //  Actuation set to EL //
      //////////////////////////
      if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_Actuator').SelectedValue == 'EL') {

        modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_IntegrityTest').SelectedValue = 'N';
        modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ControlValveFeedback').SelectedValue = 'CVF';

        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ControlSystem').SelectedValue == 'B1') {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_PA31').SelectedValue = 'N';
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_TA31').SelectedValue = 'N';
        }

        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_Extras').SelectedValue != 'N' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_Extras').SelectedValue != 'GP')
        {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_Extras').SelectedValue = 'N';
        }

        //////////////////////
        // UPS Rule with EL //
        //////////////////////
        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ControlSystem').SelectedValue == 'B1') {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_UPS').SelectedValue = 'N'
        }
        else if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_HighLimit').SelectedValue == 'N') {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_UPS').SelectedValue = 'Y'
        }

      }
      else if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_Actuator').SelectedValue == 'PN') {
        /////////////////////////
        // Actuation set to PN //
        /////////////////////////

        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ControlSystem').SelectedValue == 'B1')
        {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_IntegrityTest').SelectedValue = 'N';
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_PA31').SelectedValue = 'N';
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_TA31').SelectedValue = 'N';
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ControlValveFeedback').SelectedValue = 'N';

          if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_Extras').SelectedValue != 'N' ||
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_Extras').SelectedValue != 'GP') {
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_Extras').SelectedValue = 'N';
          }

        }


        ////////////////////////////////////////////////////////////////
        //Integrity Test Rules under DHW SC with PN actuator selected //
        ////////////////////////////////////////////////////////////////
        //Integrity Test set to YES //
        //////////////////////////////


        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_IntegrityTest').SelectedValue == 'T' &&
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ControlSystem').SelectedValue != 'B1')
        {

          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_PA31').SelectedValue = 'Y';
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_TA31').SelectedValue = 'Y';

          if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_Extras').SelectedValue != 'AP' &&
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_Extras').SelectedValue != 'GPAP')
          {
            modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_Extras').SelectedValue = 'AP';
          }
        }
        else if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_IntegrityTest').SelectedValue == 'N' &&
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ControlSystem').SelectedValue != 'B1')
        {

          // No need to do anything here
        }


        //////////////////////
        // UPS Rule with PN //
        //////////////////////
        if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ControlSystem').SelectedValue == 'B1') {
          modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_UPS').SelectedValue = 'N'
        }
      }

      //////////////////////////
      // Control System Rules //
      //////////////////////////
      if (modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_ControlSystem').SelectedValue == 'B1') {

        modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_DataCollection').SelectedValue = 'N';
        modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_TA11').SelectedValue = 'N';
        modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_TA41').SelectedValue = 'N';
        modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_TA01').SelectedValue = 'N';
        modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_FA11').SelectedValue = 'N';
        modPrefOpt.find(opt => opt.EnumerationName == 'EH_DHW_SC_Communications').SelectedValue = 'C0';

      }
    }
    //////////////////////////////////////////////////////////////////////////////////////////////
    //                                       End Of Rules
    //////////////////////////////////////////////////////////////////////////////////////////////
    return modPrefOpt;
  }




}
