import { isNull, isNullOrUndefined } from "util";
import {
  EasiheatGen4BackPressureValidation,
  EasiheatGen4DiffTempValidation,
  EasiheatGen4FlowRateValidation,
  EasiheatGen4InputValidation,
  EasiheatGen4ValidationMessage
} from "../models/inputValidation.model";
import { EasiHeatGen4Service } from '../services/easiheatgen4.services'
import { UnitType } from "../models/UnitType";
import { FormGroup, ValidationErrors } from '@angular/forms'
import { TranslatePipe, UnitConvert, UnitsConverter, UnitsService } from 'sizing-shared-lib'


export function validateInletPressure(form: FormGroup, apiService: EasiHeatGen4Service, unitRef, unitType: UnitType, translate: TranslatePipe): ValidationErrors | null {

  console.log('ValidateInletPressure');

  const control = form.get('inletPressure');
  const totalBackPressure = form.get('totalBackPressure');

  // Reset error messages first.

  if (!isNullOrUndefined(control.value)) {
    if (control.value.toString() !== "") {
      // Add details into the validation model.
      var ehInputValidation: EasiheatGen4InputValidation = new EasiheatGen4InputValidation();

      //Rounds the inputted value to decimal places set in my preferences.
      //var decimalPlaces = this.userPrefs.find(m => m.name === "PressureUnit").decimalPlaces;------------------------------------------------------------------------------------------------------------------------------------------- FIX THIS

      ehInputValidation.value = +control.value;
      ehInputValidation.units = +(unitRef !== null ? unitRef.preference.value : 0);

      apiService.inletPressureCheck(ehInputValidation, unitType.htgcc).subscribe((result: Array<EasiheatGen4ValidationMessage>) => {
        // Check if there's any validation errors? If so, set form control and error message accordingly.
        if (result && result.length > 0) {


          control.setErrors(new Error);
          control.errors.error = result[0].messageKey;
          control.errors.message = ' ( ' + result[0].value.toFixed() + ' ';
          control.errors.message += translate.transform(unitRef.preference.masterTextKey, false);
          control.errors.message += ' )';
        }

      });

      if (!isNullOrUndefined(totalBackPressure.value)) {
        if (totalBackPressure.value.toString() != "") {
          var ehInputsValidation: EasiheatGen4BackPressureValidation = new EasiheatGen4BackPressureValidation();

          ehInputsValidation.LinePressure = +control.value;
          ehInputsValidation.BackPressure = +totalBackPressure.value;
          ehInputsValidation.EhUnitType = unitType.dhw ? 'dhw' : 'htgcc';
          ehInputsValidation.Units = +(unitRef !== null ? unitRef.preference.value : 0);

          apiService.backPressureCheck(ehInputsValidation).subscribe((result: Array<EasiheatGen4ValidationMessage>) => {
            // Check if cross validation errors returned?
            if (result && result.length > 0) {

              totalBackPressure.setErrors(new Error);
              totalBackPressure.errors.error = result[0].messageKey;
              totalBackPressure.errors.message = ' ( ' + result[0].value.toFixed();
              totalBackPressure.errors.message += translate.transform(unitRef.preference.masterTextKey, false);
              totalBackPressure.errors.message += ' )';
            }
            else {
              totalBackPressure.setErrors(null);
            }
          });
        }
      }

    }
  }
  return null;
}


/*
* Validator for BackPressure control input. Checks value against set boundaries and against Inlet Pressure value and Motive Inlet Pressure value
*/
export function validateTotalBackPressure(form: FormGroup, apiService: EasiHeatGen4Service, unitRef, unitType: UnitType, translate: TranslatePipe): ValidationErrors | null {

  console.log('ValidateTotalBackPressure');

  const control = form.get('totalBackPressure');
  const inletPressure = form.get('inletPressure');
  const motiveInletPressure = form.get('motiveInletPressure');


  // 8.7.3	Pressure Differential Data Entry Note (NoteΔP)
  if (!isNullOrUndefined(inletPressure) && !isNullOrUndefined(control)) {
    if (!isNullOrUndefined(inletPressure.value) && !isNullOrUndefined(control.value)) {
      if (inletPressure.value.toString() != "" && control.value.toString() != "") {

        control.setErrors(null);
        var ehInputsValidation: EasiheatGen4BackPressureValidation = new EasiheatGen4BackPressureValidation();
        ehInputsValidation.LinePressure = +inletPressure.value;
        ehInputsValidation.BackPressure = +control.value;
        ehInputsValidation.EhUnitType = unitType.dhw ? 'dhw' : 'htgcc';
        ehInputsValidation.Units = +(unitRef !== null ? unitRef.preference.value : 0);

        apiService.backPressureCheck(ehInputsValidation).subscribe((result: Array<EasiheatGen4ValidationMessage>) => {
          // Check if cross validation errors returned?
          if (result && result.length > 0) {

            control.setErrors(new Error);
            control.errors.error = result[0].messageKey;
            control.errors.message = ' ( ' + result[0].value.toFixed() + ' ';
            control.errors.message += translate.transform(unitRef.preference.masterTextKey, false);
            control.errors.message += ' )';

          } else {
            control.setErrors(null);
          }
        });

        //if (this.motiveInletPressureAvailable) {  ------------------------------------------------------------------------------------------------------------------ FIX THIS ---------- in GEN 3 this a boolean that is set using user pref modulePreferenceService
        if ( 1+1 == 3) {
          if (motiveInletPressure.value.toString() != "") {

            motiveInletPressure.setErrors(null);

            var ehInputsValidation: EasiheatGen4BackPressureValidation = new EasiheatGen4BackPressureValidation();

            ehInputsValidation.LinePressure = +motiveInletPressure.value;
            ehInputsValidation.BackPressure = +control.value;
            ehInputsValidation.Units = +(unitRef !== null ? unitRef.preference.value : 0);

            apiService.motiveInletPressureCheck(ehInputsValidation).subscribe((result: Array<EasiheatGen4ValidationMessage>) => {
              // Check if cross validation errors returned?

              if (result && result.length > 0) {
                motiveInletPressure.setErrors(new Error);
                motiveInletPressure.errors.error = result[0].messageKey;
                motiveInletPressure.errors.message = ' ( ' + result[0].value + ' ';
                motiveInletPressure.errors.message += translate.transform(unitRef.preference.masterTextKey, false);
                motiveInletPressure.errors.message += ' )';

              } else {
                motiveInletPressure.setErrors(null);
              }
            });
          }

        }

      }
    }
  }
  return null;
}



/*
* Validator for Motive Inlet Pressure control input.
*/
export function validateMotiveInletPressure(form: FormGroup, apiService: EasiHeatGen4Service, unitRef, translate: TranslatePipe): ValidationErrors | null {

  console.log('ValidateMotiveInletPressure');

  const control = form.get('motiveInletPressure');
  const totalBackPressure = form.get('totalBackPressure');

  if (isNull(control.value)) {
    control.patchValue('', {emit: false});
    control.setErrors(null);
  }
  else {

    if (control.value.toString() !== "") {

      // Reset error messages first.
      control.setErrors(null);

      // Add details into the validation model.
      var ehInputsValidation: EasiheatGen4BackPressureValidation = new EasiheatGen4BackPressureValidation();

      ehInputsValidation.LinePressure = +control.value;
      ehInputsValidation.BackPressure = +totalBackPressure.value;
      ehInputsValidation.Units = +(unitRef !== null ? unitRef.preference.value : 0);

      apiService.motiveInletPressureCheck(ehInputsValidation).subscribe((result: Array<EasiheatGen4ValidationMessage>) => {
        // Check if there's any validation errors? If so, set form control and error message accordingly.
        if (result && result.length > 0) {

          control.setErrors(new Error);
          control.errors.error = result[0].messageKey;
          control.errors.message = ' ( ' + result[0].value + ' ';
          control.errors.message += translate.transform(unitRef.preference.masterTextKey, false);
          control.errors.message += ' )';

        }
      });
    }
  }
  return null;
}



/*
* Validator for Inlet Temperature control input.
*/
export function validateInletTemperature(form: FormGroup, apiService: EasiHeatGen4Service, unitRef, unitType: UnitType, translate: TranslatePipe ): ValidationErrors | null {

  console.log('ValidateInletTemperature');

  //tymczasowo dopuki nie skoncze wszystkich validacji i nie zrobie tooltip tak jak dla iana------------------------------------------------------------------------------------------------------------------FIX THIS
  const diffTempMsgRef = { value: '' }; 
  const control = form.get('inletTemperature');
  const outletTemperature = form.get('outletTemperature'); 

  if (!isNullOrUndefined(control.value)) {
    if (control.value.toString() !== "") {
      // Reset error messages first.
      control.setErrors(null);

      // Add details into the validation model.
      var ehInputValidation: EasiheatGen4InputValidation = new EasiheatGen4InputValidation();

      ehInputValidation.value = +control.value;
      ehInputValidation.units = +(unitRef !== null ? unitRef.preference.value : 0);

      apiService.inletTemperatureCheck(ehInputValidation).subscribe((result: Array<EasiheatGen4ValidationMessage>) => {
        // Check if there's any validation errors? If so, set form control and error message accordingly.
        if (result && result.length > 0) {

          control.setErrors(new Error);
          control.errors.error = result[0].messageKey;
          control.errors.message = ' ( ' + result[0].value.toFixed() + ' ';
          control.errors.message += translate.transform(unitRef.preference.masterTextKey, false);
          control.errors.message += ' )';
          return null;
        }



        //////////////////////////////////  ||
        //DIFFERENTIAL TEMPERATURE CHECK//  ||
        ////////////////////////////////// \  /
        //                                  \/
        if (!isNullOrUndefined(outletTemperature.value)) {
          if (outletTemperature.value.toString() != "") {
            var ehInputsValidation: EasiheatGen4DiffTempValidation = new EasiheatGen4DiffTempValidation();

            ehInputsValidation.InletTemperature = +control.value;
            ehInputsValidation.OutletTemperature = +outletTemperature.value;
            ehInputsValidation.Units = +(unitRef.preference !== null ? unitRef.preference.value : 0);

            apiService.differentialTempCheck(ehInputsValidation, unitType.dhw).subscribe((result: Array<EasiheatGen4ValidationMessage>) => {
              // Check if cross validation errors returned?
              if (result && result.length > 0) {

                control.setErrors(new Error);
                control.errors.error = result[0].messageKey;
                control.errors.message = ' ( ' + result[0].value.toFixed() + ' ';
                control.errors.message += translate.transform(unitRef.preference.masterTextKey, false);
                control.errors.message += ' )';

                outletTemperature.setErrors(new Error);
                outletTemperature.errors.error = result[0].messageKey;
                outletTemperature.errors.message = ' ( ' + result[0].value.toFixed() + ' ';
                outletTemperature.errors.message += translate.transform(unitRef.preference.masterTextKey, false);
                outletTemperature.errors.message += ' )';
                return null;
              }
              else {
                control.setErrors(null);
                outletTemperature.setErrors(null);
                return null;
              }

            });
          }
        }
        
      });
    }
  }
  return
}

/*
* Validator for Outlate Temperature control input.
*/
export function validateOutletTemperature(form: FormGroup, apiService: EasiHeatGen4Service, unitRef, uniType: UnitType, translate: TranslatePipe): ValidationErrors | null {

  console.log('ValidateOutletTemperature');

  const control = form.get('outletTemperature');
  const inletTemperature = form.get('inletTemperature');

  //tymczasowo dopuki nie skoncze wszystkich validacji i nie zrobie tooltip tak jak dla iana------------------------------------------------------------------------------------------------------------------FIX THIS
  const diffTempMsgRef = { value: '' }; 

  if (!isNullOrUndefined(control.value)) {
    if (control.value.toString() !== "") {
      // Reset error messages first.
      control.setErrors(null);

      // Add details into the validation model.
      var ehInputValidation: EasiheatGen4InputValidation = new EasiheatGen4InputValidation();

      ehInputValidation.value = +control.value;
      ehInputValidation.units = +(unitRef.preference !== null ? unitRef.preference.value : 0);

      apiService.outletTemperatureCheck(ehInputValidation).subscribe((result: Array<EasiheatGen4ValidationMessage>) => {
        // Check if there's any validation errors? If so, set form control and error message accordingly.
        if (result && result.length > 0) {

          control.setErrors(new Error);
          control.errors.error = result[0].messageKey;
          control.errors.message = ' ( ' + result[0].value.toFixed() + ' ';
          control.errors.message += translate.transform(unitRef.preference.masterTextKey, false);
          control.errors.message += ' )';
          return null;
        }

        //////////////////////////////////  ||
        //DIFFERENTIAL TEMPERATURE CHECK//  ||
        ////////////////////////////////// \  /
        //                                  \/
        if (!isNullOrUndefined(inletTemperature.value)) {
          if (inletTemperature.value.toString() != "") {

            var ehInputsValidation: EasiheatGen4DiffTempValidation = new EasiheatGen4DiffTempValidation();

            ehInputsValidation.InletTemperature = +inletTemperature.value;
            ehInputsValidation.OutletTemperature = +control.value;
            ehInputsValidation.Units = +(unitRef !== null ? unitRef.preference.value : 0);

            apiService.differentialTempCheck(ehInputsValidation, uniType.dhw).subscribe((result: Array<EasiheatGen4ValidationMessage>) => {
              // Check if cross validation errors returned?
              if (result && result.length > 0) {

                control.setErrors(new Error);
                control.errors.error = result[0].messageKey;
                control.errors.message = ' ( ' + result[0].value.toFixed() + ' ';
                control.errors.message += translate.transform(unitRef.preference.masterTextKey, false);
                control.errors.message += ' )';

                inletTemperature.setErrors(new Error);
                inletTemperature.errors.error = result[0].messageKey;
                inletTemperature.errors.message = ' ( ' + result[0].value.toFixed() + ' ';
                inletTemperature.errors.message += translate.transform(unitRef.preference.masterTextKey, false);
                inletTemperature.errors.message += ' )';

              }
              else {
                control.setErrors(null);
                inletTemperature.setErrors(null);
              }

            });
          }
        }
        
      });
    }
  }
  return null;
}

/*
* Validator for Flow Rate control input.
*/
export function validateWaterFlowRate(form: FormGroup, apiService: EasiHeatGen4Service, flowUnitRef, tempUnitRef, translate: TranslatePipe): ValidationErrors | null {

  console.log('ValidateWaterFlowRate');

  const control = form.get('waterFlowRate');
  const loadControl = form.get('load');
  const inletTemperature = form.get('inletTemperature');
  const outletTemperature = form.get('outletTemperature');

  if (!isNullOrUndefined(control.value)) {
    if (control.value.toString() !== "") {

      // Reset error messages first.
      control.setErrors(null);
      loadControl.setErrors({'incorrect': true});


      // Add details into the validation model.
      var ehInputValidation: EasiheatGen4FlowRateValidation = new EasiheatGen4FlowRateValidation();

      var decimalPlaces = 2; //------------------------------------------------------------------------w przeciwienstwie do innych tutaj zaokraglanie musi byc zrobione ----------- this.userPrefs.find(m => m.name === "VolumetricFlowUnit").decimalPlaces;--- FIX THIS

      var tempWaterFlow = parseFloat(control.value).toFixed(decimalPlaces);

      // Make sure rounding is done before the validation check as per V1 - KNG
      if (control.value != tempWaterFlow) {
        control.patchValue(parseFloat(control.value.toFixed(decimalPlaces)));
      }

      ehInputValidation.EnteredFlow = +control.value;
      ehInputValidation.FlowUnits = +(flowUnitRef !== null ? flowUnitRef.preference.value : 0);
      ehInputValidation.TemperatureRise = outletTemperature.value - inletTemperature.value;
      ehInputValidation.TemperatureUnits = +(tempUnitRef !== null ? tempUnitRef.preference.value : 0);

      apiService.waterFlowRateCheck(ehInputValidation).subscribe((result: Array<EasiheatGen4ValidationMessage>) => {
        // Check if there's any validation errors? If so, set form control and error message accordingly.
        if (result && result.length > 0) {
          control.setErrors(new Error);
          control.errors.error = result[0].messageKey;
          control.errors.message = ' ( ' + parseFloat(result[0].value.toFixed(3)) + ' ';
          control.errors.message += translate.transform(flowUnitRef.preference.masterTextKey, false);
          control.errors.message += ' )';

          control.markAsTouched();
        }
        else {
          debugger;
          control.setErrors(null);
        }
        
      });
    }
  }
  return null;
}



/*
* Validator for Load control input.
*/
export function validateLoadInput(form: FormGroup, apiService: EasiHeatGen4Service, unitRef, translate: TranslatePipe): ValidationErrors | null {

  console.log('ValidateLoadInput');

  const control = form.get('load');
  const flowControl = form.get('waterFlowRate');
  if (!isNullOrUndefined(control.value)) {
    if (control.value.toString() !== "") {
      // Reset error messages first.
      control.setErrors(null);
      flowControl.setErrors({ 'incorrect': true });

      // Add details into the validation model.
      var ehInputValidation: EasiheatGen4InputValidation = new EasiheatGen4InputValidation();

      var decimalPlaces = 2; // //------------------------------------------------------------------------w przeciwienstwie do innych tutaj zaokraglanie musi byc zrobione ----------- this.userPrefs.find(m => m.name === "LoadUnit").decimalPlaces; --- FIX THIS

      var tempLoad = parseFloat(control.value.toFixed(decimalPlaces));

      if (control.value != tempLoad) {
        control.patchValue(tempLoad, {emit : false});
      }

      ehInputValidation.value = +control.value;
      ehInputValidation.units = +(unitRef !== null ? unitRef.preference.value : 0);

      apiService.loadInputCheck(ehInputValidation).subscribe((result: Array<EasiheatGen4ValidationMessage>) => {
        // Check if there's any validation errors? If so, set form control and error message accordingly.
        if (result && result.length > 0) {
          control.setErrors(new Error);
          control.errors.error = result[0].messageKey;
          control.errors.message = ' ( ' + parseFloat(result[0].value.toFixed(3)) + ' ';
          control.errors.message += translate.transform(unitRef.preference.masterTextKey, false);
          control.errors.message += ' )';

          control.markAsTouched();
        }
        else {
          control.setErrors(null);
        }
      });
    }
  }
 return null;
}

export function hotwellTemperatureValidation(form: FormGroup, unitService: UnitsService, value: number, unitRef, translate: TranslatePipe): ValidationErrors | null {

  console.log('Validate Hotwell Temperature Input');

  let temperaure: number;
  let minTemperature: number;
  let maxTemperature: number;
  const control = form.get('hotwellTemperature');

    const unitsToConvert: UnitConvert[] = [];
    unitsToConvert.push({
      propertyName: 'hotwellTemp',
      initialValue: +value,
      initialUnitId: + unitRef.preference.value,
      targetUnitId: 146, // °C
      convertedValue: null,
    });

  unitsToConvert.push({
    propertyName: 'minimumHotwellTemp',
    initialValue: 20,
    initialUnitId: 146, // °C
    targetUnitId: + unitRef.preference.value,
    convertedValue: null,
  });

  unitsToConvert.push({
    propertyName: 'maximumHotwellTemp',
    initialValue: 100,
    initialUnitId: 146, // °C
    targetUnitId: + unitRef.preference.value,
    convertedValue: null,
  });

    const unitsConverter: UnitsConverter = {
      unitsConverter: unitsToConvert
    };

  if (control && control.enabled) {
    unitService.unitsConverter(unitsConverter).subscribe((unitsConvertedData: UnitsConverter) => {

      if (unitsConvertedData) {
        temperaure = +(unitsConvertedData.unitsConverter.find(u => u.propertyName === 'hotwellTemp').convertedValue.toFixed());
        minTemperature = +(unitsConvertedData.unitsConverter.find(u => u.propertyName === 'minimumHotwellTemp').convertedValue.toFixed());
        maxTemperature = +(unitsConvertedData.unitsConverter.find(u => u.propertyName === 'maximumHotwellTemp').convertedValue.toFixed());

        control.setErrors(null);

        if (temperaure > 100) {

          control.setErrors(new Error);
          control.errors.error = 'THE_VALUE_MUST_BE_LESS_THEN_THE_MAXIMUM_VALUE_MESSAGE';
          control.errors.message = ' ' + parseFloat(maxTemperature.toString()) + ' ';
          control.errors.message += translate.transform(unitRef.preference.masterTextKey, false);

          control.markAsTouched();
        }

        if (temperaure < 20) {

          control.setErrors(new Error);
          control.errors.error = 'THE_VALUE_MUST_BE_GREATER_THAN_THE_MINIMUM_VALUE_MESSAGE';
          control.errors.message = ' ' + parseFloat(minTemperature.toString()) + ' ';
          control.errors.message += translate.transform(unitRef.preference.masterTextKey, false);

          control.markAsTouched();
        }
      }
    })
  }
  
  return null;
}





